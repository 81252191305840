import $ from '../../common/jquery.js';

class CheckboxSubmit {
  constructor ($el) {
    this.$el = $el;
    this.events();
  };

  events () {
    this.$el.on('click', function (event) {
      $(event.currentTarget).closest('form').trigger('submit');
    });
  };

  static ready ($) {
    $('.js-checkbox-submit').ifdo(function () {
      new CheckboxSubmit($('.js-checkbox-submit'));
    });
  };
};

export default CheckboxSubmit;
