class RecordDeletionConfirmation {
  constructor ($opener, $type, $name, $content, $submit, $fields, $latestNote, $checkboxesContainer) {
    this.$opener = $opener;
    this.$type = $type;
    this.$name = $name;
    this.$content = $content;
    this.$submit = $submit;
    this.$fields = $fields;
    this.$latestNote = $latestNote;
    this.$checkboxesContainer = $checkboxesContainer;
    this.events();
  }

  events () {
    this.$opener.addEventListener('click', () => this.open());
    this.$submit.addEventListener('click', () => this.submit());
  }

  open () {
    this.$type.innerText = this.$opener.getAttribute('data-type');
    this.$name.innerText = this.$opener.getAttribute('data-name');
    this.$content.innerText = this.$opener.getAttribute('data-content');

    if (this.$latestNote)
      this.$latestNote.innerHTML = this.$opener.getAttribute('data-latest-note') || '';

    if (this.$checkboxesContainer && this.$opener.getAttribute('data-integrated-zones'))
      this.renderIntegratedZonesCheckboxes();

    this.resetFields();
  }

  resetFields () {
    this.$fields.forEach((el) => {
      el.value = el.defaultValue;
    });
  }

  renderIntegratedZonesCheckboxes () {
    this.$checkboxesContainer.replaceChildren();
    const integratedZones = JSON.parse(this.$opener.getAttribute('data-integrated-zones'));

    if (integratedZones && integratedZones.length === 1 && integratedZones[0].value === 'dnsimple') {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'record[integrated_zones][]';
      input.value = 'dnsimple';
      this.$checkboxesContainer.appendChild(input);

      this.$fields.push(input);
      return;
    }

    const headingContainer = document.createElement('div');
    headingContainer.className = 'w-100';
    this.$checkboxesContainer.appendChild(headingContainer);
    const heading = document.createElement('label');
    heading.className = 'l-block';
    heading.appendChild(document.createTextNode('Delete record from providers'));
    headingContainer.appendChild(heading);

    integratedZones.forEach((ez) => {
      const div = document.createElement('div');
      div.className = 'w-30-ns dib pr4';

      const label = document.createElement('label');
      label.className = 'text-normal';

      const input = document.createElement('input');
      input.type = 'checkbox';
      input.name = 'record[integrated_zones][]';
      input.checked = true;
      input.value = ez.value;

      label.appendChild(input);
      label.appendChild(document.createTextNode(` ${ez.name} `));
      div.appendChild(label);
      this.$checkboxesContainer.appendChild(div);

      this.$fields.push(input);
    });
  }

  submit () {
    this.$submit.href = this._buildUrlWithParams(this.$opener.dataset.url, this.$fields);
  }

  _buildUrlWithParams (path, $fields) {
    const searchParams = new URLSearchParams();

    $fields.forEach((el) => {
      if (el.type === 'checkbox' && !el.checked)
        return;

      if (el.getAttribute('name'))
        searchParams.append(el.getAttribute('name'), el.value);
    });

    if (searchParams.toString().length)
      return [path, searchParams].join('?');

    return path;
  }

  static ready () {
    const modal = document.getElementById('record-deletion-confirmation');

    if (modal)
      modal.addEventListener('show.bs.modal', (event) => {
        new RecordDeletionConfirmation(
          event.relatedTarget,
          document.querySelector('.js-deletion-confirmation-record-type'),
          document.querySelector('.js-deletion-confirmation-name'),
          document.querySelector('.js-deletion-confirmation-content'),
          document.querySelector('.js-deletion-confirmation-button'),
          [...modal.querySelectorAll('[name]')],
          document.querySelector('.js-deletion-confirmation-note'),
          document.querySelector('.js-integrated-zones-checkboxes')
        );
      });
  }
}

export default RecordDeletionConfirmation;
