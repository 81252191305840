// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. The `immediateFunc` will run every time debounce is called.

function debounce (func, wait, immediateFunc) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    if (typeof immediateFunc === 'function')
      immediateFunc.call(context);

    timeout = setTimeout(later, wait);
  };
};

export default debounce;
