const pushIfUnique = (collection, item) => {
  if (collection.indexOf(item) === -1)
    collection.push(item);
};

const hasMatching = (attr, a, b) => {
  return a[attr] === b[attr];
};

export {
  hasMatching,
  pushIfUnique
};
