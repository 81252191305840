<template>
  <div class="flash-wrapper mb2" :class="type">
    <div :class="fading && 'fading'" class="lh-title pointer flash-msg br4 pv3 ph4" @click="fade">
      {{msg}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FlashMsg',

  props: {
    duration: { type: Number, required: true },
    msg: { type: String, required: true },
    type: { type: String, required: true },
    id: { type: Number, required: true }
  },

  data () {
    return { fading: false };
  },

  mounted () {
    setTimeout(() => {
      this.fade();
    }, this.duration);
  },

  methods: {
    fade () {
      this.fading = true;
      setTimeout(() => {
        this.$emit('remove', this.id);
      }, 200);
    }
  }
};
</script>

<style scoped>

@keyframes transout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes transin {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.flash-wrapper {
  display: flex;
  max-width: 40%;
}

.flash-msg {
  animation: transin .5s ease;
  color: white;
  text-shadow: 1px 1px rgba(0,0,0,.2);
}

.flash-msg.fading {
  animation: transout .2s forwards ease-in;
}

.flash-wrapper.success .flash-msg {
  background-color: rgba(82, 163, 123, .9);
}

.flash-wrapper.danger .flash-msg {
  background-color: rgba(204, 71, 61, .9);
}

.flash-wrapper.warning .flash-msg {
  background-color: rgb(198, 143, 0, .9);
}
</style>
