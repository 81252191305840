<template>
  <component :is="label ? 'details' : 'div'" class="pointer pv3 text-extra-bold f4" open>
    <summary class="pb3" v-if="label">{{label}}</summary>
    <div class="flex flex-row flex-wrap br4">
      <service-item v-for="service in services" :service="service" :key="service.id" />
    </div>
  </component>
</template>

<script>
import ServiceItem from './item.vue';
export default {
  name: 'ServiceGroup',
  components: { ServiceItem },
  props: {
    label: { type: String, required: false },
    services: { type: Array, required: true }
  }
};
</script>

<style scoped>
summary {
  text-transform: capitalize;
}
</style>
