<template>
  <div class="w-25-l w-50 mb3" :class="classes" @click="add">
    <div class="ba b--light-gray br4 bg-white h-100 mr3 pa3 flex flex-column justify-between">
      <div class="tc">
        <div class="img-container">
          <service-image :sid="this.service.sid" loading="lazy"/>
        </div>
        <hr />
        <p class="lh-title tc f6 gray">{{service.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>

import state from '../state.js';
import ServiceImage from './image.vue';

export default {

  name: 'ServiceItem',
  components: { ServiceImage },
  props: { service: { type: Object, required: true } },

  data: () => ({ state, image: null }),

  computed: {
    classes () {
      return this.state.hasService(this.service) ? 'disabled' : 'grow';
    }
  },

  methods: {
    add () {
      this.state.serviceToAdd = this.service;
    }
  }
};
</script>

<style scoped>
.img-container {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disabled {
  filter: saturate(0);
  opacity: .5;
  cursor: not-allowed;
}

.img-container img {
  max-width: 85%;
  max-height: 60px;
}
</style>
