import ajax from '../../common/ajax.js';

class DomainFavorite {
  constructor ($btn, $star) {
    this.$btn = $btn;
    this.$star = $star;
    this.url = $btn.attr('href');
    this.listenForClick();
  };

  listenForClick () {
    this.$btn.click((e) => {
      e.preventDefault();
      this.favorite();
    });
  };

  favorite () {
    return ajax(this.url, '', 'post').then((response) => {
      this.done(response);
    }).catch((err) => {
      this.fail(err);
    });
  };

  done (response) {
    const favorited = response.getAt('favorite');
    this._clearFavoriteClasses();
    this._setFavoriteClasses(favorited);
    this._updateTooltip(favorited);
  };

  fail (err) {
    alert("Sorry we can't update favorites at this moment: " + err.responseText);
  };

  _clearFavoriteClasses () {
    this.$star.removeClass('domain-favorite-true domain-favorite-false');
  };

  _setFavoriteClasses (favorited) {
    this.$star.addClass(`domain-favorite-${favorited}`);
  }

  _updateTooltip (favorited) {
    if (favorited)
      this.$star.attr('data-original-title', 'Remove from favorites');
    else
      this.$star.attr('data-original-title', 'Add to favorites');
  };

  static ready ($) {
    return $('.js-domain-favorite-btn').ifdo(function () {
      new DomainFavorite(this, $('.js-domain-favorite-star'));
    });
  };
};

export default DomainFavorite;
