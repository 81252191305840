import DomainPriceField from './domain-price-field.js';
import DomainPriceValidator from './domain-price-validator.js';

class DomainPriceConfirmation { }

DomainPriceConfirmation.ready = ($) => {
  $('.js-domain-price-confirmation-input').ifdo(function () {
    const fields = [];

    $('.js-domain-price-confirmation-input').each(function (index, input) {
      const field = new DomainPriceField($(input), $($('.js-domain-price-confirmation-amount')[index]).text());
      fields.push(field);
    });

    new DomainPriceValidator(fields, $('.js-domain-price-confirmation-btn'));
  });
};

export default DomainPriceConfirmation;
