import { reactive, computed } from 'vue';
import ajax from '../../javascript/common/ajax.js';
import EventBus from '../../javascript/common/event-bus.js';

const state = reactive({
  domainName: null,
  accountId: null,
  records: [],
  loading: false,
  sortField: 'updated_at',
  sortReversed: false,
  types: [],

  baseUrl: computed(() => {
    return `/a/${state.accountId}/domains/${state.domainName}/onboarding/records`;
  }),

  hasNoRecords: computed(() => {
    return state.records.length === 0;
  }),

  // sort different based on what type of field we are sorting on...
  sortedRecords: computed(() => {
    const { records, sortField } = state;
    const sorted = records.sort((a, b) => {
      switch (sortField) {
        case 'updated_at':
          return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
        case 'ttl':
          return a[sortField] - b[sortField];
      }
      return a[sortField].localeCompare(b[sortField]);
    });

    return state.sortReversed ? sorted.reverse() : sorted;
  }),

  setSort (field) {
    state.sortReversed = field === state.sortField ? !state.sortReversed : false;
    state.sortField = field;
  },

  // ajax calls

  async loadTypes () {
    state.loading = true;
    const { value } = await ajax(`${state.baseUrl}/types`);
    state.types = value;
    state.loading = false;
  },

  async save (record) {
    let response;
    state.loading = true;

    try {
      response = record.id ? await ajax(`${state.baseUrl}/${record.id}`, { record }, 'PUT') : await ajax(state.baseUrl, { record }, 'POST');
      state.loadIndex();
      EventBus.$emit('flash:add', `Record ${record.name} has been saved.`);
    } catch (err) {
      response = err;
    }

    state.loading = false;
    return response;
  },

  async destroy (id) {
    let response;
    state.loading = true;

    try {
      response = await ajax(`${state.baseUrl}/${id}`, null, 'DELETE');
      EventBus.$emit('flash:add', 'Record has been removed.');
      state.loadIndex();
    } catch (err) {
      response = err;
    }

    state.loading = false;
    return response;
  },

  async edit (id) {
    state.loading = true;
    const res = await ajax(`${state.baseUrl}/${id}/edit`);
    state.loading = false;
    return res?.value;
  },

  async loadIndex () {
    state.loading = true;
    const { value: { records } } = await ajax(state.baseUrl);
    state.records = records.map(entry => entry.record).filter(record => !record.system_record);
    state.loading = false;
  }

});

EventBus.$on('dnsRecords:reload', state.loadIndex);
export default state;
