<template>
  <div class="relative">
    <applied-service-index v-if="state.all.length" />

    <template v-if="allowSearch">
      <applied-service-create v-if="state.serviceToAdd" />
      <service-search v-else />
    </template>

    <loading-indicator :delay="200" v-if="state.isLoading" />
  </div>
</template>

<script>

import state from './state.js';
import ServiceSearch from './service/search.vue';
import AppliedServiceCreate from './applied-service/create.vue';
import AppliedServiceIndex from './applied-service/index.vue';
import LoadingIndicator from '../loading-indicator/component.vue';

export default {
  provide () {
    return {
      services: this.services
    };
  },

  components: { ServiceSearch, AppliedServiceCreate, AppliedServiceIndex, LoadingIndicator },
  name: 'DnsServicesEditor',
  props: {
    services: { type: Array, required: true },
    accountId: { type: Number, required: true },
    domainName: { type: String, required: true },
    allowSearch: { type: Boolean, default: true }
  },

  data: () => ({ state }),

  methods: {
    addService (id) {
      this.serviceToAdd = this.services.find(service => service.id === id);
    }
  },

  created () {
    this.state.accountId = this.accountId;
    this.state.domainName = this.domainName;
    this.state.index();
  }
};
</script>
