<template>
  <div class="domain-name-selector-display">
    <div class="domain-name-selector-body">
      <div v-if="!loading">
        <div v-if="loadError" class="domain-name-selector-display-error"><span class="icon-warning">{{ errorMessage || "Error in loading the zone names. Please try refreshing the page." }}</span></div>
        <div v-else>
          <modal class="dib fr" :is-narrow="true" :submit-text="saveButtonName" close-text="Close" ref="modal" :initialShow="true" :on-submit="saveNames" :closeConfirmation="noNamesSelectedConfirmation" v-cloak>
              <template v-slot:header>
                <div class="mb2">{{ headerText }}</div>
              </template>
              <template v-slot:button>
              </template>
              <template v-slot:body v-if="accountExternalProviderId && allDomainNames">
                <div class="mb2">
                  <a v-on:click="selectAllCheckboxes" href="javascript:;" class="action-link" id="selectAll">
                    Select All
                  </a>
                  <span class="vr mr2 ml2"></span>
                  <a v-on:click="deselectAllCheckboxes" href="javascript:;" class="action-link" id="deselectAll">
                    Deselect All
                  </a>
                </div>
                <div class="mb4">
                  <table class="model-table mb0">
                      <thead>
                        <tr>
                          <th v-if="showLoadingModal"><p>{{ loadingModalText }}</p></th>
                          <th v-else>
                            <TableSearch class="form-control" :initial-limit="7" :placeholder="`Search by zone name...`" tfoot-class="bg-moon-gray" colspan="2">
                              <template v-slot:no-items>
                                <p>No zones found.</p>
                              </template>
                            </TableSearch>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="domainName in allDomainNames" v-bind:key="domainName.externalIdentifier">
                            <td class="no-padding">
                              <label v-if="isDomainNameDisabled(domainName)" class="db pa3">
                                <input type="checkbox" v-model="selectedExternalIdentifiers" :value="domainName.externalIdentifier" :aria-label="`Select ${domainName.name}`" class="w2" disabled>
                                <span class="dib" role="tooltip" title="This domain name already exists in another account">
                                  <span :title="domainName.externalIdentifier" class="text-subtle">{{ domainName.name }}</span>
                                </span>
                              </label>

                              <label v-else class="db pa3">
                                <input type="checkbox" v-model="selectedExternalIdentifiers" :value="domainName.externalIdentifier" :aria-label="`Select ${domainName.name}`" class="w2">
                                <span :title="domainName.externalIdentifier">{{ domainName.name }}</span>
                              </label>
                            </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </template>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
import EventBus from '../../javascript/common/event-bus.js';
import Modal from '../modal/component.vue';
import TableSearch from '../table-search/component.vue';
import Tooltip from 'bootstrap/js/dist/tooltip';

const LINK_MODE_NEW = 'newLink';
const LINK_MODE_EXISTING = 'existingLink';

export default {
  props: {
    url: { type: String, required: true },
    fetchExternalZonesUrl: { type: String, required: true },
    returnTo: { type: String, required: true },
    successReturnTo: { type: String }
  },
  components: {
    Modal,
    TableSearch
  },
  mounted () {
    EventBus.$on('external-provider-link-success', this.loadForNewLink);
    EventBus.$on('external-provider-manage-domain-names', this.loadForDomainNameManagement);
  },
  updated () {
    const $tooltipEls = [...this.$el.querySelectorAll('[role="tooltip"][title]')];
    $tooltipEls.forEach(($el) => {
      new Tooltip($el, { title: $el.getAttribute('title') });
    });
  },
  data () {
    return {
      allDomainNames: [],
      selectedExternalIdentifiers: [],
      existingDomainNamesInOtherAccounts: [],
      accountExternalProviderId: null,
      loading: true,
      showLoadingModal: false,
      loadError: false,
      errorMessage: null,
      wasLinked: false,
      mode: null
    };
  },
  computed: {
    noNamesSelectedConfirmation () {
      return this.mode === LINK_MODE_NEW
        ? 'As no zone names are selected, no zones will be synced for the provider. Continue?'
        : null;
    },
    headerText () {
      return this.mode === LINK_MODE_NEW ? 'Add Integrated Zones' : 'Manage Integrated Zones';
    },

    saveButtonName () {
      if (this.errorMessage)
        return null;

      return this.mode === LINK_MODE_NEW ? 'Add zones' : 'Apply changes';
    },

    loadingModalText () {
      return this.errorMessage || 'Loading zones..';
    },

    selectedDomainNames: {
      get () {
        // Map selectedExternalIdentifiers back to domain name objects
        return this.selectedExternalIdentifiers.map(externalIdentifier =>
          this.allDomainNames.find(domain => domain.externalIdentifier === externalIdentifier)
        );
      },
      set (domainNames) {
        // Keep selectedExternalIdentifiers in sync
        this.selectedExternalIdentifiers = domainNames.map(domain => domain.externalIdentifier);
      }
    }
  },
  methods: {
    loadForNewLink (eventData) {
      this.reset();
      this.$nextTick(() => {
        this.mode = LINK_MODE_NEW;
        if (eventData.hasFailed || eventData.hasErrored) {
          this.loadError = true;
          this.loading = false;
          this.errorMessage = eventData.errorMessage;
        } else if (eventData.response) {
          const resp = eventData.response;
          this.accountExternalProviderId = resp.accountExternalProviderId;
          this.allDomainNames = resp.domainNames;
          this.existingDomainNamesInOtherAccounts = resp.existingDomainNames;
          this.loadError = false;
          this.loading = false;
          this.errorMessage = null;
          this.wasLinked = true;
        }
      });
    },
    loadForDomainNameManagement (eventData) {
      this.reset();
      this.$nextTick(() => {
        this.mode = LINK_MODE_EXISTING;
        EventBus.$emit('external-provider-view-domain-names-modal-close');
        const resp = eventData.response;
        this.accountExternalProviderId = resp.accountExternalProviderId;
        this.selectedExternalIdentifiers = resp.linkedDomainNames.map(dnObject => dnObject.external_identifier);
        this.showLoadingModal = true;
        this.loading = false;
        this.wasLinked = false;

        ajax(this.fetchExternalZonesUrl, {
          account_external_provider_id: this.accountExternalProviderId
        }, 'get').then(
          (fetchExternalZonesResponse) => {
            const result = fetchExternalZonesResponse.get();
            if (result.error)
              this.errorMessage = result.error;
            else if (result.response) {
              const resp = result.response;
              this.allDomainNames = resp.zones.map(zone => ({ externalIdentifier: zone.external_identifier, name: zone.name }));
              this.existingDomainNamesInOtherAccounts = resp.existing_domain_names.map(zone => ({ externalIdentifier: zone.external_identifier, name: zone.name }));
              this.showLoadingModal = false;
              this.loadError = false;
              this.errorMessage = null;
            }
          },
          (_error) => {
            window.location.href = this.returnTo;
          }
        );
      });
    },
    reset () {
      this.allDomainNames = [];
      this.selectedExternalIdentifiers = [];
      this.accountExternalProviderId = null;
      this.existingDomainNamesInOtherAccounts = [];
      this.loading = true;
      this.loadError = false;
      this.showLoadingModal = false;
      this.errorMessage = null;
      this.wasLinked = false;
      this.mode = null;
    },
    isDomainNameDisabled (domainName) {
      return !!this.existingDomainNamesInOtherAccounts.find(dn => dn.externalIdentifier === domainName.externalIdentifier);
    },
    selectAllCheckboxes () {
      const enabledDomainNames = this.allDomainNames.filter(domainName => !this.isDomainNameDisabled(domainName));
      this.selectedDomainNames = enabledDomainNames;
    },
    deselectAllCheckboxes () {
      this.selectedDomainNames = [];
    },
    saveNames () {
      if (this.mode === LINK_MODE_NEW && this.selectedExternalIdentifiers.length === 0)
        return window.location.href = this.returnTo;

      const domainNames = this.selectedDomainNames.map(domainName =>
        `${domainName.name}:${domainName.externalIdentifier}`
      );

      return ajax(this.url, {
        account_external_provider: {
          id: this.accountExternalProviderId,
          domain_names: domainNames
        },
        was_linked: this.wasLinked
      }, 'put').then(
        (_response) => window.location.href = this.successReturnTo || this.returnTo,
        (_error) => window.location.href = this.returnTo
      );
    }
  }
};
</script>
