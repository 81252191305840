class RecordTtl {
  constructor ($, $value, $input, $time) {
    this.$ = $;
    this.$value = $value;
    this.$input = $input;
    this.$time = $time;
    this.event();
    this.updateTime(this.$input.val());
  }

  event () {
    this.$value.click((event) => {
      event.preventDefault();

      const value = this.$(event.currentTarget).data('value');

      this.updateInput(value);
      this.updateTime(value);
    });

    this.$input.on('keypress', (event) => {
      if (event.which === 45)
        return event.preventDefault();
    });

    this.$input.on('keyup', (event) => {
      this.updateTime(this.$(event.currentTarget).val());
    });
  }

  updateInput (value) {
    this.$input.val(value);
  }

  updateTime (value) {
    let time = '';

    const days = Math.floor(value / 86400);
    value -= days * 86400;

    const hours = Math.floor(value / 3600) % 24;
    value -= hours * 3600;

    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    const seconds = value % 60;

    if (days > 0)
      time += days + 'd ';

    if (hours > 0)
      time += hours + 'h ';

    if (minutes > 0)
      time += minutes + 'm ';

    if (seconds > 0)
      time += seconds + 's';

    return this.$time.text(time.trim());
  }

  static ready ($) {
    $('.js-record-ttl-value').ifdo(function () {
      return new RecordTtl($, this, $('.js-record-ttl-input'), $('.js-record-ttl-human-time'));
    });
  }
};

export default RecordTtl;
