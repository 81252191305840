import ajax from '../../javascript/common/ajax.js';

class UserClickEventHandler {
  constructor () {
    this.elementsWithDataEvent = document.querySelectorAll('[data-user-click-event-name]');
    this.handleEvents();
  }

  handleEvents () {
    this.elementsWithDataEvent.forEach(element => {
      element.addEventListener('click', (event) => {
        event.preventDefault();

        const params = { event: element.getAttribute('data-user-click-event-name') };
        const path = element.getAttribute('data-user-click-event-path');

        ajax(path, { body: JSON.stringify(params) }, 'post').then(
          response => {
            this.navigateTo(element.href);
          },
          _error => {
            // Bugsnag should catch this and we should redirect regardless
            this.navigateTo(element.href);
          });
      });
    });
  }

  navigateTo (href) {
    window.location.href = href;
  }
}

export default UserClickEventHandler;
