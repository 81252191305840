<template>
  <a href="#" @click.prevent="sort" :class="state.sortField === field && 'sorted'">

    <slot />

    <span class="direction">
      <span v-if="state.sortReversed">↑</span>
      <span v-else>↓</span>
    </span>
  </a>
</template>

<script>
export default {
  name: 'SortLink',
  inject: ['state'],
  props: {
    field: { type: String, required: true }
  },

  methods: {
    sort () {
      this.state.setSort(this.field);
    }
  }
};
</script>

<style scoped>
a {
  color: black;
}

a .direction {
  display: none;
}

a.sorted .direction {
  margin-left: .2em;
  display: inline;
}

a:hover {
  text-decoration: underline;
}
</style>
