<template>
  <div>
    <div v-for="item in list">
      <input v-bind:name="name" v-bind:value="item" type="hidden">
    </div>
    <div v-for="(input, index) in inputs" class="input-set pv1">
      <input v-if="inputMode" v-focus v-on:blur="inputBlur" v-model="input.value" v-on:keyup="keyup" class="form-control">
      <input v-else v-on:blur="inputBlur" v-model="input.value" v-on:keyup="keyup" class="form-control">
      <span v-if="suffix" class="input-set-info">.{{ suffix }}</span>
      <button v-if="canDelete(index)" type="button" v-on:click="remove(input.value)" class="input-set-info btn btn-ghost icon-align-center icon-trash"></button>
    </div>
    <div class="mt3">
      <button type="button" v-on:click="add" class="btn btn-default">Add</button>
    </div>
  </div>
</template>

<script>
import { FILTER_UNIQ } from '../../javascript/common/filters.js';

export default {
  props: {
    name: String,
    formId: String,
    suffix: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  mounted () {
    this.initializeModels();

    const form = document.getElementById(this.formId);
    if (form)
      form.addEventListener('keypress', function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          return false;
        }
      }, false);
  },
  data () {
    return {
      list: [],
      inputs: [
        { value: '' }
      ],
      inputMode: false
    };
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus();
      }
    }
  },
  methods: {
    add () {
      this.inputs.push({ value: '' });
      this.inputBlur();
      this.inputMode = true;
    },
    remove (value) {
      this.inputs.splice(this.inputs.findIndex(function (input) {
        return input.value === value;
      }), 1);
      this.inputBlur();
    },
    keyup (event) {
      if (event.keyCode === 13) {
        this.inputMode = true;
        this.add();
        event.preventDefault();
        return false;
      }
    },
    inputBlur () {
      const items = this.inputs.map((item) => item.value).filter(FILTER_UNIQ);

      const names = items.map((item) => {
        if (item.trim().length === 0)
          return this.suffix;
        else
          return `${item}.${this.suffix}`;
      });

      this.setList(names);
    },
    initializeModels () {
      this.inputs = this.items.map((item) => {
        if (item === this.suffix)
          return { value: '' };
        else
          return { value: item.replace(`.${this.suffix}`, '') };
      });

      this.setList(this.items);
    },
    setList (names) {
      this.list = this.isEmpty(names) ? [this.rootDomain()] : names;
    },
    rootDomain () {
      return this.suffix.replace(/^\./, '');
    },
    isEmpty (array) {
      if ((array.length === 0) || (array.length === 1 && array[0] === ''))
        return true;

      return false;
    },
    canDelete (index) {
      return index > 0;
    }
  }
};
</script>
