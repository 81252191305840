<template>
  <tr @keydown.enter="save" @keydown.escape="() => $emit('cancelled')">
    <td class="record-column-type" valign="top">
      <span v-if="recordId">{{ record.record_type}}</span>

      <select ref="recordType" class="form-select" v-model="record.record_type" style="min-width: 7em;" v-else>
        <option v-for="type in state.types" :key="type" :value="type">{{type}}</option>
      </select>
    </td>

    <td valign="top">
      <input class="form-control" placeholder="Name (leave empty for root records)" :class="classesFor('name')" type="text" v-model="record.name"
        required style="min-width: 15em;"/>
      <errors-for-field :errors="errors" field="name" />
      <errors-for-field :errors="errors" field="base" />
    </td>

    <td valign="top">
      <input type="text" placeholder="Content" class="form-control" :class="classesFor('content')"
        v-model="record.content" required />
      <errors-for-field :errors="errors" field="content" />
    </td>

    <td valign="top">
      <select class="form-select" v-model="record.ttl" style="min-width: 8rem;">
        <option v-for="ttl in ttls" :key="ttl.value" :value="ttl.value">{{ttl.label}}</option>
      </select>
    </td>

    <td valign="top">
      <div class="btn-group" role="group" aria-label="actions">
        <button type="submit" class="btn btn-primary" :class="buttonClasses"
          @click="save">{{saveText}}</button>
        <slot />
      </div>
    </td>
  </tr>
</template>

<script>
import { ttls } from './constants.js';
import ErrorsForField from './errors-for-field.vue';
import EventBus from '../../javascript/common/event-bus.js';

export default {
  name: 'DnsForm',
  inject: ['state'],

  components: { ErrorsForField },

  props: {
    saveText: { type: String, required: false, default: '' },
    recordId: { type: Number, required: false, default: null }
  },

  data () {
    return {
      record: { record_type: 'A', name: '', content: '', ttl: ttls[1].value },
      errors: null,
      ttls
    };
  },

  computed: {
    buttonClasses () {
      return [
        this.recordId ? 'icon-check' : 'icon-plus',
        this.saveText.length ? '' : 'icon-align-center'
      ];
    }
  },

  async mounted () {
    if (this.recordId)
      this.record = await this.state.edit(this.recordId);
  },

  methods: {

    classesFor (field) {
      // only return the classes if we have validated and have errors
      if (!this.errors) return '';
      return this.errors && (field in this.errors || this.errors.base) ? 'is-invalid' : 'is-valid';
    },

    async save () {
      const response = await this.state.save(this.record);

      if (response.isClientError())
        this.errors = response?.message?.errors || { base: ['Validation Failed.'] };
      else if (response.statusCode >= 500)
        EventBus.$emit('flash:add', 'There was a problem, please try again', 'danger');
      else {
        if (!this.recordId) {
          this.record.name = this.record.content = '';
          this.$refs.recordType.focus();
        }

        this.errors = null;
        this.$emit('saved', response.value.record);
      }
    }
  }
};
</script>

<style scoped>
tr.add-form td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
