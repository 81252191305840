const MAP_RECORD = (record) => {
  return {
    id: record.id,
    type: record.record_type.toLowerCase(),
    name: record.name,
    content: record.content
  };
};

const MAP_TRIM = (item) => item.trim();
const MAP_FLAT = (arr) => [].concat.apply([], arr);

export {
  MAP_FLAT,
  MAP_RECORD,
  MAP_TRIM
};
