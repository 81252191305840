import * as WebAuthnJSON from '@github/webauthn-json';
import ajax from '../../common/ajax.js';

class AuthenticateUserSecurityKeys {
  constructor ($, $link) {
    this.$ = $;
    this.$link = $link;

    this.listenForClick();
  }

  listenForClick () {
    this.$link.on('click', (event) => {
      const newUrl = this.$link.data('newUrl');
      const failureUrl = this.$link.data('failure-url');
      ajax(newUrl).then(
        response => {
          this.prompt_for_security_key(response.value);
        },
        error => {
          this.handleError(error, failureUrl);
        });
    });
  };

  prompt_for_security_key (publicKey) {
    publicKey.userVerification = 'discouraged';
    const failureUrl = this.$link.data('failure-url');
    WebAuthnJSON.get({ publicKey }).then((credentials) => {
      this.verifyOnServer(credentials);
    }).catch((error) => {
      this.handleError(error, failureUrl);
    });
  };

  verifyOnServer (credentials) {
    const verifyUrl = this.$link.data('verify-url');
    const successUrl = this.$link.data('success-url');
    const failureUrl = this.$link.data('failure-url');
    ajax(verifyUrl, { body: JSON.stringify(credentials) }, 'post').then(
      response => {
        this.redirect(successUrl);
      },
      error => {
        this.handleError(error, failureUrl);
      });
  };

  handleError (error, failureUrl) {
    let errorCode = error && error.code ? error.code : null;
    if (error instanceof DOMException)
      errorCode = 'registration_not_found';
    const redirectPath = errorCode ? failureUrl + '?error_code=' + errorCode : failureUrl;
    this.redirect(redirectPath);
  }

  redirect (url) {
    // This is to support Safari / Yubikey 5C NFC
    setTimeout(() => location.assign(url), 250);
  }
};

AuthenticateUserSecurityKeys.ready = ($) => {
  $('#authenticate-user-security-key').ifdo(function () {
    new AuthenticateUserSecurityKeys($, this);
  });
};

export default AuthenticateUserSecurityKeys;
