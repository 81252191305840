import ajax from '../../common/ajax.js';

let once;

class NavbarDomainLabelFilter {
  constructor ($, $btn, $dropdown, getLabelsPath, filterPath, maxLabels) {
    this.$ = $;
    this.$btn = $btn;
    this.$dropdown = $dropdown;
    this.getLabelsPath = getLabelsPath;
    this.filterPath = filterPath;
    this.MAX_LABELS = maxLabels;
    this.labelItemClass = 'js-navbar-label-item';

    this.listenToEvents();
  }

  listenToEvents () {
    this.$btn.click((event) => {
      once = once || this.retrieveLabels();
    });
  }

  retrieveLabels () {
    ajax(this.getLabelsPath).then(
      response => {
        this.showLabels(response.get());
      },
      err => {
        alert('Sorry cannot retrieve your labels at this moment. ' + err.message);
      }
    );
  }

  showLabels (labels) {
    const labelCount = Object.keys(labels).length;
    const permittedLabels = Object.keys(labels).slice(0, this.MAX_LABELS);

    this.clearLabels();

    if (labelCount > 0)
      this.$dropdown.append("<li role='separator' class='divider dropdown-divider " + this.labelItemClass + "'></li>");

    permittedLabels.forEach((label) => {
      const html = "<li class='" + this.labelItemClass + "'><a href='" + (this.labelPath(label)) + "' class='dropdown-item'>" + labels[label] + ' labeled with <strong>' + label + '</strong></a></li>';
      this.$dropdown.append(html);
    });

    if (labelCount > this.MAX_LABELS)
      this.showModalWithAllLabels(labels);
  }

  showModalWithAllLabels (labels) {
    const viewAllLink = "<li class='" + this.labelItemClass + "'><a href='#' class='dropdown-item' data-bs-toggle='modal' data-bs-target='#show-all-labels'>View all labels</a></li>";

    this.$dropdown.append(viewAllLink);

    Object.keys(labels).forEach((label) => {
      const html = "<li class='" + this.labelItemClass + "'><a href='" + (this.labelPath(label)) + "'><strong>" + label + "</strong><span class='text-subtle'> " + labels[label] + ' </span></a></li>';
      this.$('.js-all-labels-modal').append(html);
    });
  }

  clearLabels () {
    this.$('.' + this.labelItemClass).remove();
  }

  labelPath (label) {
    const separator = this.filterPath.indexOf('?') === -1 ? '?' : '&';
    return '' + this.filterPath + separator + 'label=' + label + '&name=' + label;
  }
}

NavbarDomainLabelFilter.ready = ($) => {
  $('.js-navbar-filter-btn').ifdo(function () {
    new NavbarDomainLabelFilter($, this, $('.js-navbar-filter-dropdown'), $('.js-navbar-get-labels-path').text(), $('.js-navbar-filter-label-path').text(), $('.js-navbar-filter-max-labels').text());
  });
};

export default NavbarDomainLabelFilter;
