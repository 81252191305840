<template>
  <input type="text" v-model="q">
</template>

<script>
export default {
  props: {
    colspan: {
      type: String,
      default: '1'
    },
    tfootClass: {
      type: String,
      default: ''
    },
    findTable: {
      type: Function,
      default: ($el) => {
        return $el.closest('table, [data-table-search-wrapper]');
      }
    },
    itemsSelector: {
      type: String,
      default: 'tbody > tr'
    },
    itemContentSelector: {
      type: String,
      default: 'label'
    },
    initialLimit: {
      type: Number,
      default: 10
    },
    footerElementTypes: {
      type: Array,
      default () { return ['tfoot', 'tr', 'td']; }
    }
  },
  data () {
    return {
      table: null,
      q: '',
      limit: this.initialLimit
    };
  },
  mounted () {
    this.table = this.findTable(this.$el);
    this.filterRows();
  },
  watch: {
    q () {
      this.limit = this.initialLimit;
      this.filterRows();
    }
  },
  methods: {
    more () {
      this.limit += this.initialLimit;
      this.filterRows();
    },
    filterRows () {
      const q = this.q.toLowerCase();
      const rows = [...this.table.querySelectorAll(this.itemsSelector)];
      const filtered = rows.filter(this.filterRow(q));
      const filteredLength = filtered.length;
      const limited = filtered.slice(0, this.limit);
      const limitedLength = limited.length;

      this.hideRows(rows);
      this.showRows(limited);

      if (limitedLength === 0)
        this.noItems();
      else if (limitedLength === filteredLength)
        this.removeFooter();
      else
        this.paginate(filteredLength, limitedLength);
    },
    filterRow (q) {
      return (row) => {
        const content = [...row.querySelectorAll(this.itemContentSelector)].map((el) => el.textContent).join(' ');
        const hasMatchingContent = this.contains(content, q);

        if (hasMatchingContent)
          return true;

        const hasMatchingSelectTagSelection = !![...row.querySelectorAll('select')].filter(($select) => {
          return this.contains($select.options[$select.selectedIndex].text, q);
        }).length;

        return hasMatchingSelectTagSelection;
      };
    },
    contains (str, q) {
      return str.toLowerCase().indexOf(q) !== -1;
    },
    hideRows (rows) {
      rows.forEach((r) => r.style.display = 'none');
    },
    showRows (rows) {
      rows.forEach((r) => r.style.display = '');
    },
    noItems () {
      const text = this.$slots['no-items']()[0].children.trim();
      const el = document.createTextNode(text);
      this.createFooter(el);
    },
    paginate (filteredLength, limitedLength) {
      const a = document.createElement('a');
      a.href = 'javascript:;';
      a.addEventListener('click', () => this.more());
      a.textContent = `Show more (showing ${limitedLength} of ${filteredLength} filtered items)`;
      this.createFooter(a);
    },
    removeFooter () {
      const tfoot = this.table.querySelector(this.footerElementTypes[0]);

      if (tfoot)
        this.table.removeChild(tfoot);
    },
    createFooter ($el) {
      this.removeFooter();

      const tfoot = document.createElement(this.footerElementTypes[0]);
      const tr = document.createElement(this.footerElementTypes[1]);
      const td = document.createElement(this.footerElementTypes[2]);

      td.setAttribute('colspan', this.colspan);
      td.appendChild($el);
      tr.appendChild(td);

      tfoot.className = this.tfootClass;
      tfoot.appendChild(tr);

      this.table.appendChild(tfoot);
    }
  }
};
</script>
