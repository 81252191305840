import ajax from '../../common/ajax.js';
import debounce from '../../utils/debounce.js';

class RecordEditorFilter {
  constructor ($filterInput, $container, url) {
    this.$filterInput = $filterInput;
    this.$container = $container;
    this.url = url;
    this.lazyFilter = debounce(this.filter, 300);
    this.eventListener();
  }

  eventListener () {
    this.$filterInput.keyup(() => {
      this.lazyFilter();
    });
  }

  filter () {
    return ajax(this.url, {
      filter: `${this.$filterInput.val()}`.trim()
    }, 'get', 'html')
      .then((response) => this.showResults(response.get()))
      .catch(() => this.showError());
  }

  showResults (data) {
    this.$container.html(data);
  }

  showError () {
    this.$container.html('Sorry, the filter is not working at this moment. Please contact support.');
  }
}

RecordEditorFilter.ready = ($) => {
  $('.js-record-editor-filter-input').ifdo(function () {
    new RecordEditorFilter($(this), $('.js-record-editor-filter-container'), $('.js-record-editor-filter-url').text());
  });
};

export default RecordEditorFilter;
