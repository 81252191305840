<template>
  <div class="search-bar pa3 z-4 top-0 bg-near-white br4">
    <auto-complete ref="autoComplete" input-class="pa4 pv3 br-pill w-100 ba b--gray bw2" type="search"
      placeholder="Search by Name" :items="serviceNames" @select="selected" @matches="filter" />

    <service-group :services="matches" v-if="matches.length" />

    <template v-else>
      <service-group v-for="cat in categories" :key="cat" :label="cat" :services="servicesByCategory(cat)" />
    </template>
  </div>
</template>

<script>
import ServiceGroup from './group.vue';
import AutoComplete from '../../autocomplete/component.vue';
import state from '../state.js';

export default {
  inject: ['services'],
  name: 'ServiceSearch',
  components: { AutoComplete, ServiceGroup },

  data: () => ({ matches: [], state, categories: [] }),

  methods: {

    servicesByCategory (cat) {
      return this.services.filter(({ category }) => category === cat);
    },

    selected (serviceName) {
      const match = this.services.find(service => service.name === serviceName);
      if (match) {
        this.state.serviceToAdd = match;
        this.reset();
      }
    },

    reset () {
      this.$refs.autoComplete.reset();
      this.$refs.autoComplete.$el.querySelector('input').blur();
    },

    filter (matches) {
      this.matches = this.services.filter(service => matches.includes(service.name));
    }
  },

  mounted () {
    this.categories = this.services.reduce((acc, { category }) => {
      if (!acc.includes(category)) acc.push(category);
      return acc;
    }, []);
  },

  computed: {
    serviceNames () {
      return this.services.map(service => service.name);
    }
  }
};
</script>

<style scoped>
.search-bar {
  position: sticky;
  inset-block-start: 0;
}
</style>
