<template>
    <a v-bind:href="href" v-bind:class="classes">{{ text }}</a>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
export default {
  props: {
    loadText: String,
    loadUrl: String,
    classes: String,
    loadNow: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    if (this.loadNow && this.loadUrl !== undefined) {
      this.text = this.loadText;
      this.load();
    }
  },
  data () {
    return {
      href: '',
      text: ''
    };
  },
  methods: {
    load () {
      const err = 'Cannot load data';

      this.text = 'Loading...';

      ajax(this.loadUrl).then(
        response => {
          this.href = response.getAt('href', '');
          this.text = response.getAt('text', err);
        },
        error => this.text = error.getMessage(err)
      );
    }
  }
};
</script>
