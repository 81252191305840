import ajax from '../../common/ajax.js';

class RegistrantVerificationAlerts {
  constructor ($, $el, raaAlertCount, $totalAlertCount, $loadingMsg, $noAlertMsg) {
    this.$ = $;
    this.$el = $el;
    this.raaAlertCount = raaAlertCount;
    this.$totalAlertCount = $totalAlertCount;
    this.$loadingMsg = $loadingMsg;
    this.$noAlertMsg = $noAlertMsg;
    this.fetchInfo();
  }

  fetchInfo () {
    const path = this.$el.data('path');

    return ajax(path, '', 'get', 'html').then((response) => {
      this.show(response.get());
    });
  }

  show (html) {
    this.$el.html(html);
    this.updateAlertCount();
  }

  updateAlertCount () {
    const totalAlertCount = parseInt(this.$totalAlertCount.text()) || 0;
    const raaAlertCount = parseInt(this.$el.find(this.raaAlertCount).text()) || 0;

    this.$totalAlertCount.text(totalAlertCount + raaAlertCount);

    this.removeLoadingMessage(raaAlertCount);
  }

  removeLoadingMessage (raaAlertCount) {
    this.$loadingMsg.addClass('is-hidden');

    if (raaAlertCount === 0)
      this.$noAlertMsg.removeClass('is-hidden');
  }

  static ready ($) {
    $('.js-raa-alerts').ifdo(function () {
      new RegistrantVerificationAlerts($, this, '.js-raa-alert-count', $('.js-dashboard-alert-count'), $('.js-dashboard-alert-loading'), $('.js-dashboard-alert-none'));
    });
  }
};

export default RegistrantVerificationAlerts;
