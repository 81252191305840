import ajax from '../../common/ajax.js';
import Awesomplete from 'awesomplete';

class DomainTypeAhead {
  constructor (awesomplete, $input, $form, domainNamePath) {
    this.awesomplete = awesomplete;
    this.$input = $input;
    this.$form = $form;
    this.domainNamePath = domainNamePath;
    this.getDomains();
    this.submitEvent();
  }

  getDomains () {
    ajax(this.domainNamePath).then((response) => {
      this.initLiveSearch(response);
    }).catch(() => {});
  }

  initLiveSearch (response) {
    this.domains = response.getAt('domains', []);
    this.awesomplete.list = this.domains.map((domain) => domain.name);
    this.awesomplete.minChars = 1;
    this.awesomplete.autoFirst = true;
  }

  submitEvent () {
    this.$input.on('awesomplete-selectcomplete', (event) => {
      this.$form.submit();
    });
  }

  static ready ($) {
    $('.js-domain-search-name-input').ifdo(function () {
      new DomainTypeAhead(
        new Awesomplete(document.querySelector('.js-domain-search-name-input')),
        $(this),
        $('.js-domain-name-type-ahead-form'),
        $('.js-domain-name-path').text()
      );
    });
  };
};

export default DomainTypeAhead;
