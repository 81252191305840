import initVue from '../javascript/common/init-vue.js';
import components from '../javascript/app/components.js';

import '../javascript/common/shims.js';
import '../javascript/legacy/app/index.js';
import '../javascript/support/index.js';
import '../javascript/analytics/posthog.js';

import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-apacheconf';
import 'prismjs/components/prism-go';
import 'prismjs/plugins/toolbar/prism-toolbar.min.css';
import 'prismjs/plugins/toolbar/prism-toolbar.min';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js';

import UserClickEventHandler from '../javascript/analytics/user_click_event_handler.js';
new UserClickEventHandler();

initVue(components);
