import $ from '../../common/jquery.js';

export default {
  start (el) {
    $(el).addClass('processing-target').prepend('<div class="processing-message icon-refresh">Loading...</div>');
  },

  stop (el) {
    $(el).removeClass('processing-target').children('.processing-message').remove();
  }
};
