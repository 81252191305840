import { createApp } from 'vue';
import Support from '../../components/support/src/components/app/component.vue';

import '../../components/support/src/style/index.scss';

const SUPPORT_ID = 'dnsimple-support-wrapper';
const QUERY_ATTRIBUTE = 'query';

document.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.createElement('div');
  const support = document.createElement('support');
  const script = document.body.querySelectorAll(`script[${QUERY_ATTRIBUTE}]`)[0];
  const q = script ? script.getAttribute(QUERY_ATTRIBUTE) || '' : '';

  wrapper.id = SUPPORT_ID;
  wrapper.appendChild(support);
  support.setAttribute(QUERY_ATTRIBUTE, q);

  document.body.appendChild(wrapper);

  const app = createApp(Support);
  app.mount(`#${SUPPORT_ID}`);
});
