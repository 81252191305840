import ajax from '../../common/ajax.js';

class CardForm {
  constructor (dom, stripe, elements, returnUrl, errorUrl, cancelUrl, operation) {
    this.dom = dom;
    this.stripe = stripe;
    this.elements = elements;
    this.returnUrl = returnUrl;
    this.errorUrl = errorUrl;
    this.cancelUrl = cancelUrl;
    this.operation = operation;
    this.submitEnabledValue = this.dom.submit.value;
  }

  hook () {
    this.hookStripeElements();
    this.hookSubmit();
    this.clearError();
  }

  hookStripeElements () {
    const paymentElement = this.elements.create('payment');
    paymentElement.mount(this.dom.paymentElement);

    const addressElement = this.elements.create('address', {
      mode: 'billing'
    });
    addressElement.mount(this.dom.addressElement);
  }

  hookSubmit () {
    this.dom.form.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.showSpinner();
      this.disableSubmitButton();
      if (this.operation === 'payment')
        await this.confirmPayment();
      else
        await this.confirmSetup();
      this.enableSubmitButton();
      this.hideSpinner();
    });
  }

  async confirmPayment () {
    const elements = this.elements;

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.returnUrl
      }
    });

    if (error)
      if (this.cancelUrl) {
        await this.confirmPaymentError(error);
        return new Promise(() => window.location.replace(this.cancelUrl));
      } else {
        this.displayError(error.message);
        return this.confirmPaymentError(error);
      }
  }

  async confirmSetup () {
    const { error } = await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrl
      }
    });

    if (error) {
      this.displayError(error.message);
      return this.confirmSetupError(error);
    }
  }

  async confirmSetupError (error) {
    if (error.setup_intent && this.errorUrl)
      return ajax(this.errorUrl, {
        setup_intent: error.setup_intent.id,
        error: error.message
      }, 'post');
  }

  async confirmPaymentError (error) {
    if (error.payment_intent && this.errorUrl)
      return ajax(this.errorUrl, {
        payment_intent: error.payment_intent.id,
        error: error.message
      }, 'post');
  }

  enableSubmitButton () {
    this.dom.submit.value = this.submitEnabledValue;
    this.dom.submit.disabled = false;
  }

  disableSubmitButton () {
    this.dom.submit.value = this.dom.submit.dataset.disabledValue;
    this.dom.submit.disabled = true;
  }

  showSpinner () {
    if (this.dom.spinner) {
      this.dom.body.classList.add('overflow-hidden');
      this.dom.spinner.classList.remove('dn');
    }
  }

  hideSpinner () {
    if (this.dom.spinner) {
      this.dom.body.classList.remove('overflow-hidden');
      this.dom.spinner.classList.add('dn');
    }
  }

  displayError (message) {
    this.dom.messages.textContent = message;
    this.dom.messages.classList.remove('dn');
    this.dom.messages.classList.add('alert', 'alert-error');
  }

  clearError () {
    this.dom.messages.textContent = '';
    this.dom.messages.classList.add('dn');
    this.dom.messages.classList.remove('alert', 'alert-error');
  }

  static ready () {
    const dom = {
      form: document.querySelector('#card-form'),
      body: document.querySelector('body'),
      submit: document.querySelector('#card-form-submit'),
      spinner: document.querySelector('#spinner-modal'),
      messages: document.querySelector('#messages'),
      paymentElement: document.querySelector('#payment-element'),
      addressElement: document.querySelector('#address-element')
    };

    if (dom.form) {
      const stripePK = document.querySelector('#stripejs').dataset.pk;
      const stripe = window.Stripe(stripePK);

      const returnUrl = dom.form.dataset.returnUrl;
      const errorUrl = dom.form.dataset.setupErrorUrl;
      const cancelUrl = dom.form.dataset.cancelUrl;
      const clientSecret = dom.form.dataset.clientSecret;
      const operation = dom.form.dataset.operation;
      const elements = stripe.elements({
        clientSecret,
        appearance: {
          rules: {
            '.Label': {
              fontSize: '16px',
              fontWeight: '700'
            },
            '.Input': {
              border: '1px solid #e4e4e4',
              borderRadius: '3px',
              boxShadow: 'inset 1px 1px 4px rgba(0, 0, 0, 0.15)',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '1.5',
              padding: '7px 12px'
            }
          }
        }
      });

      new CardForm(dom, stripe, elements, returnUrl, errorUrl, cancelUrl, operation).hook();
    }
  }
}

export default CardForm;
