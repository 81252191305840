import ajax from '../../common/ajax';

class RegistrantVerificationBulk {
  constructor ($, $selector) {
    $selector.each((index, element) => {
      this.fetchInfo($(element));
    });
  }

  fetchInfo ($element) {
    const url = $element.data('path');

    return ajax(url).then((response) => {
      this.displayIndicator(response.getAt('info', {}), $element);
    });
  }

  displayIndicator (info, $element) {
    if (info.state === 'suspended' || info.state === 'pending_suspension')
      return $element.removeClass('is-hidden');
  }

  static ready ($) {
    $('.js-raa-indicator').ifdo(function () {
      new RegistrantVerificationBulk($, this);
    });
  }
};

export default RegistrantVerificationBulk;
