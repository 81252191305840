<template>
  <ul v-if="errorMessages.length">
    <li v-for="message in errorMessages" :key="message" class="text-danger">{{ message }}</li>
  </ul>
</template>

<script>
export default {
  name: 'ErrorsForField',
  props: {
    field: { type: String, required: true },
    errors: { type: Object, required: false, default: () => null }
  },

  computed: {
    errorMessages () {
      const errors = this.errors?.[this.field] || [];
      return [...new Set(errors)];
    }
  }
};
</script>

<style scoped>
ul {
  margin-bottom:0;
  padding-left: 1em;
}

ul:first-of-type{
  margin-top: .5em;
}

ul li {
  font-size: .95rem;
  line-height: 1.2em;
  padding-bottom: .5em;
}
</style>
