import ajax from '../../common/ajax.js';

class SecondaryDnsProvider {
  constructor ($, providerSelector, providerFormSelector, providerNameSelector, providerNameservers, providerIPs, customFormSelector, nameServerSetFormSelector, editMode) {
    this.$ = $;
    this.providerSelector = providerSelector;
    this.providerFormSelector = providerFormSelector;
    this.providerNameSelector = providerNameSelector;
    this.providerNameservers = providerNameservers;
    this.providerIPs = providerIPs;
    this.customFormSelector = customFormSelector;
    this.nameServerSetFormSelector = nameServerSetFormSelector;
    this.editMode = editMode;
    this.editWarning = true;

    this.hideAllForms();
    this.registerChangeEvent();

    if (this.$(this.providerSelector + ':checked').length > 0)
      this.showForm(this.$(this.providerSelector + ':checked'));
  }

  registerChangeEvent () {
    this.hideAllForms();
    this.$(this.providerSelector).change((event) => {
      this.providerChange(event);
    });
  }

  providerChange (event) {
    this.hideAllForms();

    if (this.editMode === 'edit' && this.editWarning) {
      if (confirm('Warning! Changing provider will erase your current configuration. Continue?')) {
        this.getProviderConfiguration(event.target);
        this.showForm(event.target);
        this.editWarning = false;
      }
    } else {
      this.getProviderConfiguration(event.target);
      this.showForm(event.target);
    }
  }

  showForm (provider) {
    const providerName = this.$(provider).val();

    if (providerName === 'custom')
      this.showCustomForm();
    else if (providerName === 'name_server_set')
      this.showNameServerSetForm();
    else
      this.showProviderForm(provider);
  }

  showProviderForm (provider) {
    this.$(this.providerNameSelector).val(this.$(provider).val());
    this.$(this.providerFormSelector).removeClass('is-hidden');
  }

  getProviderConfiguration (provider) {
    const url = this.$(provider).data('url');

    if (url === null || typeof url === 'undefined')
      return;

    return ajax(url)
      .then((response) => {
        const provider = response.get();

        this.$(this.providerNameservers).val(provider.name_servers.join('\n'));
        this.$(this.providerIPs).val(provider.whitelisted_ips.join('\n'));
      }).catch(() => {
        alert('We could not retrieve the configuration for this provider at this time. Please try the custom option.');
      });
  }

  showCustomForm () {
    this.$(this.customFormSelector).removeClass('is-hidden');
    this.$(this.providerNameSelector).val('custom');
  }

  showNameServerSetForm () {
    this.$(this.nameServerSetFormSelector).removeClass('is-hidden');
    this.$(this.providerNameSelector).val('name_server_set');
    this.$(this.providerIPs).val('');
  }

  hideAllForms () {
    this.$(this.providerFormSelector).addClass('is-hidden');
    this.$(this.customFormSelector).addClass('is-hidden');
    this.$(this.nameServerSetFormSelector).addClass('is-hidden');
  }

  static ready ($) {
    $('.js-dns-provider').ifdo(function () {
      new SecondaryDnsProvider($, '.js-dns-provider', '.js-provider-form', '.js-provider-name', '.js-provider-nameservers', '.js-provider-ips', '.js-custom-form', '.js-name-server-set-form', $('.js-edit-mode').text());
    });
  }
};

export default SecondaryDnsProvider;
