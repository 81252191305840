<template>
  <img :src="resolvedUrl" />
</template>

<script>
export default {

  name: 'ServiceImage',
  props: {
    sid: { type: String, required: true }
  },

  data: () => ({ resolvedUrl: null }),

  watch: {
    sid: {
      async handler () {
        const mod = await import(`../../../images/services/${this.sid}.png`);
        this.resolvedUrl = mod.default;
      },
      immediate: true
    }
  }

};
</script>
