class RecordCreationPreview {
  constructor ($nameInput, $recordInput, $namePreview, $recordPreview, $container) {
    this.$nameInput = $nameInput;
    this.$recordInput = $recordInput;
    this.$namePreview = $namePreview;
    this.$recordPreview = $recordPreview;
    this.$container = $container;
    this.setup();
  }

  setup () {
    if (!this.$nameInput || !this.$recordInput || !this.$namePreview || !this.$recordPreview)
      this.$container.style.display = 'none';
    else {
      this.events();
      this.updateName();
      this.updateRecord();
    }
  }

  events () {
    this.$nameInput.addEventListener('keyup', () => this.updateName());
    this.$nameInput.addEventListener('focusout', () => this.updateName());
    this.$recordInput.addEventListener('keyup', () => this.updateRecord());
    this.$recordInput.addEventListener('focusout', () => this.updateRecord());
  }

  updateName () {
    const value = this.$nameInput.value.length > 0 ? (this.$nameInput.value) + '.' : this.$nameInput.value;
    this.$namePreview.innerText = value;
  }

  updateRecord () {
    this.$recordPreview.innerText = this.$recordInput.value;
  }

  static ready () {
    const nameInput = document.querySelector('#record_name');
    const $container = document.querySelector('.js-record-preview-container');

    if (nameInput && $container)
      new RecordCreationPreview(
        nameInput,
        document.querySelector('#record_content'),
        document.querySelector('#name-display'),
        document.querySelector('#content-display'),
        $container
      );
    ;
  }
}

export default RecordCreationPreview;
