import $ from '../../common/jquery.js';
import EventBus from '../../common/event-bus.js';

class ExternalProviderLink {
  constructor ($, providerType, $form) {
    this.$ = $;
    this.providerType = providerType;
    this.$form = $form;

    this.listenForSubmit();
  }

  listenForSubmit () {
    this.$form.on('ajax:success', (event) => {
      const ref = event.detail;
      const xhr = ref[2];

      const linkResponse = JSON.parse(xhr.response).response;
      const accountExternalProviderId = linkResponse.account_external_provider_id;
      const respDomainNames = linkResponse.domain_names;
      const respExistingDomainNames = linkResponse.existing_domain_names;
      const domainNames = respDomainNames.map(dn => ({ name: dn.name, externalIdentifier: dn.external_identifier }));
      const existingDomainNames = respExistingDomainNames.map(dn => ({ name: dn.name, externalIdentifier: dn.external_identifier }));
      if (this.providerType === 'DNS')
        EventBus.$emit('external-provider-link-success', { response: { accountExternalProviderId, domainNames, existingDomainNames } });
      EventBus.$emit('external-provider-link-modal-close');
    });

    this.$form.on('ajax:error', (event) => {
      const ref = event.detail;
      const xhr = ref[2];

      const response = JSON.parse(xhr.response);
      this.handleError(response.error);
    });
  };

  handleError (error) {
    $('#external-provider-link-callback-error').text('Error: ' + error);
    $('#external-provider-link-error-display').show();
    EventBus.$emit('external-provider-link-modal-close');
  }
};

ExternalProviderLink.ready = ($) => {
  $('.external-dns-provider-link-form').each(function () {
    $(this).ifdo(function () {
      new ExternalProviderLink($, 'DNS', this);
    });
  });
};

export default ExternalProviderLink;
