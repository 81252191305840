import ajax from '../../common/ajax.js';
import Awesomplete from 'awesomplete';

class DomainLiveSearch {
  constructor (awesomplete, $input, $form, liveSearchPath) {
    this.awesomplete = awesomplete;
    this.$input = $input;
    this.$form = $form;
    this.liveSearchPath = liveSearchPath;
    this.events();
  }

  getDomains () {
    if (!this.domains)
      ajax(this.liveSearchPath).then((response) => {
        this.initLiveSearch(response);
      }).catch(() => {});
  }

  initLiveSearch (response) {
    this.domains = response.getAt('domains', []);
    this.awesomplete.list = this.domains.map((domain) => domain.name);
    this.awesomplete.minChars = 1;
    this.awesomplete.autoFirst = true;
  }

  events () {
    this.$input.on('focus', () => {
      this.getDomains();
    });

    this.$input.on('awesomplete-selectcomplete', (event) => {
      this.showDomain(this.$input.val());
    });
  }

  showDomain (name) {
    const domain = this.domains.find((domain) => domain.name === name);

    this.goToUrl(domain.path);
  }

  goToUrl (href) {
    window.location.assign(href);
  }

  static ready ($) {
    $('.js-awesomplete-input').ifdo(function () {
      new DomainLiveSearch(
        new Awesomplete(document.querySelector('.js-awesomplete-input')),
        this,
        $('.js-navbar-search-form'),
        $('.js-navbar-search-path').text()
      );
    });
  };
};

export default DomainLiveSearch;
