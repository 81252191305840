<template>
  <table class="model-table">
    <thead>
      <tr>
        <th class="tc">
          <sort-link field="record_type">Type</sort-link>
        </th>
        <th>
          <sort-link field="name">Name</sort-link>
        </th>
        <th>
          <sort-link field="content">Content</sort-link>
        </th>
        <th class="tr">
          <sort-link field="ttl">Refresh rate</sort-link>
        </th>
        <th>&nbsp;</th>
      </tr>
    </thead>

    <tbody v-if="state.hasNoRecords">
      <tr>
        <td class="tc" colspan="5">
          <div class="pv4">Add your first DNS record<br>↓</div>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <template v-for="record in state.sortedRecords">
        <dns-form :record-id="editingId" @saved="saved" @cancelled="cancel" v-if="editingId === record.id"
          :key="record.id">
          <button @click="cancel" class="btn btn-default icon-times-circle icon-align-center"></button>
        </dns-form>

        <tr v-else :key="record.id" :class=" lastUpdatedId === record.id ? 'updated' : ''" :id="`record-${record.id}`">
          <td class="record-column-type">{{ record.record_type }}</td>
          <td class="record-column-name">
            <span class="pl2">{{ record.name }}</span>
          </td>
          <td class="record-column-content">
            <span class="pl2">{{ record.content }}</span>
          </td>
          <td class="record-column-details nowrap"><span class="icon-clock" />{{ record.ttl }}</td>

          <td>
            <div class="btn-group" role="group" aria-label="actions">
              <button class="btn btn-default icon-edit icon-align-center" @click="editingId = record.id"></button>
              <button class="btn btn-default icon-trash icon-align-center" @click="destroy(record.id)"></button>
            </div>
          </td>
        </tr>
      </template>
    </tbody>

    <tfoot class="bg-near-white">
      <dns-form save-text="Add" @saved="saved" class="add-form" />
    </tfoot>
  </table>
</template>

<script>
import DnsForm from './form.vue';
import SortLink from './sort-link.vue';

export default {
  components: { DnsForm, SortLink },
  name: 'DnsIndex',
  inject: ['state'],

  data: () => ({ editingId: null, lastUpdatedId: null }),

  methods: {
    cancel () {
      this.editingId = null;
    },

    async saved (record) {
      this.lastUpdatedId = record.id;
      this.editingId = null;
    },

    destroy (id) {
      if (window.confirm('Are you sure you wish to delete this record?'))
        this.state.destroy(id);
    }
  }
};
</script>

<style scoped>

table.model-table {
  margin-bottom: -20px;
}

tfoot {
  position: sticky;
  inset-block-end: 0;
}

thead {
  position: sticky;
  background-color: white;
  z-index: 100;
  inset-block-start: 0;
}

tfoot tr {
  box-shadow: inset 0px 4px 2px rgba(0,0,0,.1);
}

tbody tr.updated {
  animation-name: highlight;
  animation-duration: 3s;
}

tbody tr.system {
  opacity: .4;
  pointer-events: none;
}

@keyframes highlight {
  from { background-color: lightyellow;}
  to { background-color: inherit}
}
</style>
