<template>
  <div class="flex mv3">
    <transition-group name="component-reveal">
      <div class="service dns-item br4 bg-near-white ph2 pv1 mr2 relative" v-for="applied in state.all" :key="applied.id">
        <service-image :sid="applied.sid" loading="lazy" />
        <a href="#" class="item-remove icon-times-circle" @click.prevent="() => destroy(applied)"></a>
      </div>
    </transition-group>
  </div>
</template>

<script>
import ServiceImage from '../service/image.vue';
import state from '../state.js';

export default {
  components: { ServiceImage },
  name: 'AppliedServicesIndex',
  data: () => ({ state }),

  methods: {
    async destroy (appliedService) {
      if (window.confirm('Are you sure you wish to remove this service?'))
        await this.state.destroy(appliedService);
    }
  }
};
</script>

<style scoped>
.service {
  width: 10em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service img {
  max-width: 80%;
}
</style>
