import { reactive, computed } from 'vue';
import EventBus from '../../javascript/common/event-bus.js';
import ajax from '../../javascript/common/ajax.js';

const state = reactive({

  all: [],
  isLoading: false,
  accountId: null,
  domainName: null,
  serviceToAdd: null,

  baseUrl: computed(() => {
    return `/a/${state.accountId}/domains/${state.domainName}/onboarding/applied_services`;
  }),

  async index () {
    state.isLoading = true;
    const { value } = await ajax(state.baseUrl);
    state.all = value;
    state.isLoading = false;
  },

  async create (service, settings) {
    state.isLoading = true;
    let response;

    try {
      response = await ajax(state.baseUrl, { service_id: service.id, settings }, 'POST');
      EventBus.$emit('flash:add', `Service ${service.name} has been added.`);
      EventBus.$emit('dnsRecords:reload');
      state.index();
    } catch (err) {
      response = err;
    }

    state.isLoading = false;
    return response;
  },

  async destroy (appliedService) {
    state.isLoading = true;
    const { value } = await ajax(`${state.baseUrl}/${appliedService.id}`, null, 'DELETE');
    state.isLoading = false;

    EventBus.$emit('flash:add', `Service ${appliedService.name} has been removed.`);
    EventBus.$emit('dnsRecords:reload');
    state.index();
    return value;
  },

  hasService (service) {
    return state.all.find(applied => applied.id === service.id);
  }

});

export default state;
