<template>
  <div v-if="show">
    <div v-bind:class="statusClass">
      <span v-html="text"></span>
      <a v-bind:href="linkUrl">{{ linkText }}</a>
    </div>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
export default {
  props: ['loadUrl', 'alertStatus', 'alertClass'],
  mounted () {
    if (this.loadUrl !== null && this.loadUrl !== undefined)
      this.load();
  },
  data () {
    return {
      show: false,
      text: '',
      linkUrl: '',
      linkText: ''
    };
  },
  methods: {
    load () {
      ajax(this.loadUrl).then(response => {
        if (!response.contains('alert_text'))
          return;

        this.text = response.getAt('alert_text', '');
        this.linkText = response.getAt('link_text', '');
        this.linkUrl = response.getAt('link_url', '');
        this.show = true;
      });
    }
  },
  computed: {
    statusClass () {
      return this.alertClass ? this.alertClass : `alert alert-${this.alertStatus}`;
    }
  }
};
</script>
