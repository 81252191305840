<template>
    <div class="footer">
        <p>
            <a
                href="https://dnsimple.com/contact"
                class="button"
                target="_blank"
                >Get in touch</a
            >
            Can't find what you are looking for?
        </p>
    </div>
</template>

<script>
export default {
  props: ['app']
};
</script>
