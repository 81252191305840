import ajax from '../../common/ajax.js';
import Processing from '../common/processing.js';

function buildHtmlElement (htmlSource) {
  const container = document.createElement('container');
  container.innerHTML = htmlSource.trim();
  return container.childNodes[0];
}

class CertificateDcvEmails {
  constructor (formGroupEl, formEl) {
    this.formGroupEl = formGroupEl;
    this.loadedIndicator = formGroupEl.querySelector('.js-certificate-dcv-email-selected');
    this.formEl = formEl;
  }

  load (path) {
    this.showLoadingIndicator();
    return ajax(path).then(
      response => this.showResult(response),
      error => this.showError(error)
    );
  }

  showLoadingIndicator () {
    Processing.start(this.formEl);
  }

  hideLoadingIndicator () {
    Processing.stop(this.formEl);
  }

  showResult (response) {
    this.loadedIndicator.setAttribute('checked', true);
    response.getAt('dcv_emails', []).forEach(mail => {
      const div = buildHtmlElement(`
        <div class="radio">
          <input type="radio" value="${mail}" name="certificate[dcv_email]"/>
          <label>${mail}</label>
        </div>
      `);
      this.formGroupEl.appendChild(div);
    });
    this.hideLoadingIndicator();
  }

  showError (error) {
    this.formGroupEl.appendChild(buildHtmlElement('<div>There was an error loading the validation emails for the certificate.</div>'));
    if (error.isServiceUnavailable()) {
      const reloadLink = buildHtmlElement('<a href="#">Please reload the page.</a>');
      reloadLink.addEventListener('click', () => location.reload());
      this.formGroupEl.appendChild(reloadLink);
    } else
      this.formGroupEl.appendChild(buildHtmlElement('<div>Please contact support@dnsimple.com.</div>'));

    this.hideLoadingIndicator();
  }

  hideLoadedFlag () {
    this.loadedIndicator.style.display = 'none';
  }

  static ready () {
    const formGroupEl = document.querySelector('.js-certificate-dcv-emails');
    if (formGroupEl === null)
      return;

    const formEl = document.querySelector('.bootstrap-form');

    const component = new CertificateDcvEmails(formGroupEl, formEl);
    component.hideLoadedFlag();
    return component.load(formGroupEl.dataset.certificateDcvEmailsPath);
  }
}

export default CertificateDcvEmails;
