class UseDnsimpleNameServers {
  constructor ($submitBtn, $table, $affordanceBtn, dnsimpleNameServers) {
    this.$submitBtn = $submitBtn;
    this.$table = $table;
    this.$affordanceBtn = $affordanceBtn;
    this.dnsimpleNameServers = dnsimpleNameServers;

    if (this.$submitBtn && this.dnsimpleNameServers && this.dnsimpleNameServers.length > 0 && this.$table)
      this.listenForClick();

    if (this.$table && this.$affordanceBtn)
      this.moveAffordanceToTableFooter();
  }

  listenForClick () {
    this.$submitBtn.addEventListener('click', () => {
      this.$table.clearExpandableTable();

      this.dnsimpleNameServers.forEach((nameServer) => {
        this.$table.addExpandableTableItem({ name: nameServer });
      });
    });
  }

  moveAffordanceToTableFooter () {
    const $tfootTd = this.$table.querySelector('tfoot td:first-child');
    const $affordanceParent = this.$affordanceBtn.parentNode;

    $tfootTd.appendChild($affordanceParent);
    $affordanceParent.style.display = 'inline-block';
  }
}

UseDnsimpleNameServers.ready = () => {
  const $submitBtn = document.getElementById('use_dnsimple_name_servers_btn');
  const $dnsimpleNameServersInput = document.getElementById('default_dnsimple_name_servers');
  const $table = document.getElementById('name_server_names_table');
  const $affordanceBtn = document.getElementById('use_dnsimple_name_servers_affordance');

  let dnsimpleNameServers = [];

  if ($dnsimpleNameServersInput) {
    const dnsimpleNameServersRawData = $dnsimpleNameServersInput.getAttribute('data-name-servers');
    dnsimpleNameServers = JSON.parse(dnsimpleNameServersRawData);
  }
  new UseDnsimpleNameServers($submitBtn, $table, $affordanceBtn, dnsimpleNameServers);
};

export default UseDnsimpleNameServers;
