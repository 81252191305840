<template>
  <p class="db mt2 mb0 pa3 br2 bg-light-gray">
    <Modal class="dib fr" :is-narrow="true" :on-submit="saveNames" :has-close="!isReadOnly" :submit-text="`Select ${resourceType}`" ref="modal" v-cloak>
      <template v-slot:button>
        <a v-if="isReadOnly" href="" class="dib">Details</a>
        <a v-else href="" class="dib">Change</a>
      </template>
      <template v-slot:header>
        <span>Select<span v-if="isReadOnly">ed</span> {{resourceType}}</span>
      </template>
      <template v-slot:body>
        <div :class="`${isReadOnly ? '' : 'nb4'}`">
          <table class="model-table mb0">
            <thead>
              <tr>
                <th>
                  <TableSearch class="form-control" :initial-limit="7" :placeholder="`Search for ${resourceType}...`" tfoot-class="bg-moon-gray" colspan="2">
                    <template v-slot:no-items>
                      <p>No {{resourceType}} found.</p>
                    </template>
                  </TableSearch>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="resource in resourcesToShow">
                <td class="no-padding">
                  <label class="db pa3">
                    <input type="checkbox" v-model="selectionBuffer[resource.id]" :aria-label="`Select ${resource.name}`" :disabled="isReadOnly" class="w2">
                    {{ resource.name }}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>
    <span aria-label="summary" v-html="summary"></span>
    <input v-for="selectedResourceId in selectedResourceIds" :key="`selected-names-${selectedResourceId}-${fieldPrefix}`" type="hidden" :name="`${fieldPrefix}[]`" :value="selectedResourceId">
  </p>
</template>

<script>
import TableSearch from '../table-search/component.vue';
import Modal from '../modal/component.vue';

export default {
  components: {
    TableSearch,
    Modal
  },
  props: {
    resources: {
      type: Object,
      default () { return {}; }
    },
    fieldPrefix: {
      type: String,
      default: ''
    },
    initialIds: {
      type: Array,
      default () { return []; }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    resourceType: {
      type: String,
      default: 'resources'
    }
  },
  data () {
    const selectionBuffer = {};

    this.initialIds.forEach((id) => {
      selectionBuffer[id] = true;
    });

    return {
      selectionBuffer,
      selectedResourceIds: this.initialIds
    };
  },
  computed: {
    resourcesToShow () {
      if (this.isReadOnly)
        return this.resources.filter((resource) => this.selectedResourceIds.indexOf(resource.id) !== -1);

      return this.resources;
    },

    summary () {
      if (this.resources.length && this.resources.length === this.selectedResourceIds.length)
        return `Applies to all account ${this.resourceType}`;

      const selectedResourceNames = this.selectedResourceIds.map((id) => this.getNameFromId(id));

      if (this.selectedResourceIds.length > 2)
        return `Applies to <strong>${selectedResourceNames.slice(0, 2).join(', ')}, and ${selectedResourceNames.length - 2} more</strong>`;
      else if (selectedResourceNames.length)
        return `Applies to <strong>${selectedResourceNames.join(', ')}</strong>`;
      else
        return `Applies to all account ${this.resourceType}`;
    }
  },
  methods: {
    getNameFromId (id) {
      return this.resources.find((resource) => parseInt(resource.id) === parseInt(id)).name;
    },
    saveNames () {
      const selectedResourceIds = [];

      for (const key in this.selectionBuffer)
        if (this.selectionBuffer[key])
          selectedResourceIds.push(key);

      this.selectedResourceIds = selectedResourceIds;
    }
  }
};
</script>
