import $ from '../../common/jquery.js';
import AddNameServerSet from './add_name_server_set.js';
import CheckboxSubmit from './checkbox_submit.js';
import DashboardDomainFavorite from './dashboard_domain_favorite';
import DomainFavorite from './domain_favorite';
import DomainListLabels from './domain_list_labels.js';
import DomainLiveSearch from './domain_live_search.js';
import DomainPriceConfirmation from './domain-price-confirmation.js';
import DomainTypeAhead from './domain_type_ahead.js';
import EditNameServers from './edit-name-servers.js';
import ExtendedAttributes from './extended-attributes.js';
import NavbarDomainLabelFilter from './navbar-domain-label-filter.js';
import RecordDeletionConfirmation from './record-deletion-confirmation.js';
import RecordEditorFilter from './record-editor-filter.js';
import RecordCreationPreview from './record-creation-preview.js';
import RecordTtl from './record_ttl.js';
import RegistrantVerificationAlerts from './registrant_verification_alerts.js';
import RegistrantVerificationBulk from './registrant_verification_bulk.js';
import SecondaryDnsProvider from './secondary_dns_provider.js';
import Stripe from './stripe.js';
import TemplateRecords from './template_records.js';
import VanityNameServers from './vanity_name_servers.js';
import CertificateDcvEmails from '../../legacy/app/certificate-dcv-emails';
import Certificates from './certificates.js';
import MobileNav from '../common/mobile-nav';
import Signup from './signup';
import RegisterUserSecurityKeys from './register_user_security_keys';
import AuthenticateUserSecurityKeys from './authenticate_user_security_keys';
import SystemStatus from './system_status';
import KeyboardShortcuts from './keyboard_shortcuts';
import CardForm from './card_form';
import ExternalProviderLink from './external_provider_link';
import UseDnsimpleNameServers from './use_dnsimple_name_servers.js';

$(() => {
  AddNameServerSet.ready();
  CheckboxSubmit.ready($);
  DashboardDomainFavorite.ready($);
  DomainFavorite.ready($);
  DomainPriceConfirmation.ready($);
  DomainListLabels.ready($);
  DomainLiveSearch.ready($);
  DomainTypeAhead.ready($);
  EditNameServers.ready($);
  ExtendedAttributes.ready($);
  NavbarDomainLabelFilter.ready($);
  RecordDeletionConfirmation.ready($);
  RecordEditorFilter.ready($);
  RecordCreationPreview.ready($);
  RecordTtl.ready($);
  RegistrantVerificationAlerts.ready($);
  RegistrantVerificationBulk.ready($);
  SecondaryDnsProvider.ready($);
  Stripe.ready($);
  TemplateRecords.ready($);
  VanityNameServers.ready($);
  CertificateDcvEmails.ready($);
  Certificates.ready($);
  MobileNav.ready($);
  Signup.ready();
  RegisterUserSecurityKeys.ready($);
  AuthenticateUserSecurityKeys.ready($);
  SystemStatus.ready($);
  KeyboardShortcuts.ready();
  CardForm.ready($);
  ExternalProviderLink.ready($);
  UseDnsimpleNameServers.ready();
});
