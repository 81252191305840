class ExtendedAttributes {
  constructor ($, json) {
    this.$ = $;
    this.data = JSON.parse(json);
    this.hideAllDependentFields();
    this.events();
  }

  hideAllDependentFields () {
    (this.data || []).forEach((item) => {
      (item.options || []).forEach((option) => {
        (option.requires || []).forEach((field) => {
          this.$('#' + field.name).addClass('is-hidden');
        });
      });
    });
  }

  events () {
    const _this = this;

    this.$('[name^=extended_attribute]:visible').each(function () {
      if (_this.$(this).val())
        _this.handleChange(_this.$(this).data('index'), _this.$(this).val());
    });

    this.$('[name^=extended_attribute]').on('change', function () {
      _this.handleChange(_this.$(this).data('index'), this.value);
    });
  }

  handleChange (index, value) {
    const options = this.data[index].options;
    const option = options.find((opt) => {
      return opt.value === value;
    });

    if (option && option.requires.length > 0)
      this.showDependentFields(option);
    else
      this.hideDependentFields(options, option);
  }

  showDependentFields (option) {
    option.requires.forEach((field) => {
      this.displayFieldSection(field);
      this.makeLabelRequired(field);
      this.makeFieldRequired(field, true);
    });
  }

  displayFieldSection (field) {
    this.$('#' + field.name).removeClass('is-hidden');
  }

  makeLabelRequired (field) {
    this.$('#' + field.name + ' span:first').text('');
  }

  makeFieldRequired (field, boolean) {
    this.$('#extended_attribute_' + field.name).prop('required', boolean);
  }

  hideDependentFields (options, option) {
    options.forEach((option) => {
      option.requires.forEach((field) => {
        this.hideFieldSection(field);
        this.makeFieldRequired(field, false);
      });
    });
  }

  hideFieldSection (field) {
    this.$('#' + field.name).addClass('is-hidden');
  }
}

ExtendedAttributes.ready = ($) => {
  $('.js-extended-attributes-json').ifdo(function () {
    new ExtendedAttributes($, $(this).text());
  });
};

export default ExtendedAttributes;
