import $ from '../../common/jquery.js';
import * as WebAuthnJSON from '@github/webauthn-json';
import ajax from '../../common/ajax.js';

class RegisterUserSecurityKey {
  constructor ($, $form) {
    this.$ = $;
    this.$form = $form;

    this.listenForRecoveryCodeConfirm();
    this.listenForSubmit();
  }

  listenForRecoveryCodeConfirm () {
    $('#security-key-recovery-code-confirm').on('click', (event) => {
      $('#section-security-key-register').addClass('is-hidden');
      $('#section-security-key-create-recovery-code').addClass('is-hidden');
      $('#section-security-key-confirm-recovery-code').removeClass('is-hidden');
    });
  };

  listenForSubmit () {
    this.$form.on('ajax:beforeSend', (event) => {
      return $('#security-key-register-button').length && $('#security-key-register-button').is(':visible');
    });

    this.$form.on('ajax:success', (event) => {
      const ref = event.detail;
      const xhr = ref[2];

      const serverPublicKey = JSON.parse(xhr.response);

      this.prompt_for_security_key(serverPublicKey);
    });

    this.$form.on('ajax:error', (event) => {
      const ref = event.detail;
      const xhr = ref[2];

      const response = JSON.parse(xhr.response);
      this.handleError(response.error);
    });
  };

  prompt_for_security_key (serverPublicKey) {
    WebAuthnJSON.create({ publicKey: serverPublicKey }).then((credentials) => {
      this.verifyOnServer(JSON.stringify(credentials));
    }).catch((error) => {
      this.handleError(error);
    });
  };

  verifyOnServer (credentials) {
    const nickname = this.$form.find('input[name=nickname]').val();
    const inputRecoveryCodeVal = this.$form.find('input[name=input_recovery_code]').val();
    const verifyUrl = this.$form.data('verify-url');
    const successUrl = this.$form.data('success-url');
    ajax(verifyUrl, { body: credentials, input_recovery_code: inputRecoveryCodeVal, nickname }, 'post').then(
      response => {
        window.location.href = successUrl;
      },
      error => {
        this.handleError(error);
      });
  };

  handleError (error) {
    $('#security-key-callback-error').text('Error: ' + error.message);
    $('#security-key-callback-error-display').show();
  }
};

RegisterUserSecurityKey.ready = ($) => {
  $('#register-user-security-key').ifdo(function () {
    new RegisterUserSecurityKey($, this);
  });
};

export default RegisterUserSecurityKey;
