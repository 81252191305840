class AddNameServerSet {
  constructor ($submitBtn, $dropdown, $table, $affordanceBtn) {
    this.$submitBtn = $submitBtn;
    this.$dropdown = $dropdown;
    this.$table = $table;
    this.$affordanceBtn = $affordanceBtn;

    this.listenForClick();
    this.moveAffordanceToTableFooter();
  }

  listenForClick () {
    this.$submitBtn.addEventListener('click', () => {
      const $selectedOption = this.$dropdown.children[this.$dropdown.selectedIndex];
      const rawData = $selectedOption.getAttribute('data-name-servers');
      const nameServers = JSON.parse(rawData);

      nameServers.forEach((nameServer) => this.$table.addExpandableTableItem(nameServer));
    });
  }

  moveAffordanceToTableFooter () {
    const $tfootTd = this.$table.querySelector('tfoot td:first-child');
    const $affordanceParent = this.$affordanceBtn.parentNode;

    $tfootTd.appendChild($affordanceParent);
    $affordanceParent.style.display = 'inline-block';
  }
}

AddNameServerSet.ready = () => {
  const $submitBtn = document.getElementById('add_name_server_set_btn');
  const $dropdown = document.getElementById('add_name_server_set_dropdown');
  const $table = document.getElementById('name_server_names_table');
  const $affordanceBtn = document.getElementById('add_name_server_set_affordance');

  if ($submitBtn && $dropdown && $table && $affordanceBtn)
    new AddNameServerSet($submitBtn, $dropdown, $table, $affordanceBtn);
};

export default AddNameServerSet;
