class DomainPriceValidator {
  constructor (fields, $btn) {
    this.fields = fields;
    this.$btn = $btn;
    this.disableBtn();
    this.listenForInput();
  }

  listenForInput () {
    this.fields.forEach((field) => {
      field.$input.keyup((event) => {
        return this.verifyInput();
      });
    });
  }

  verifyInput () {
    if (this.allFieldsVerified())
      this.enableBtn();
    else
      this.disableBtn();
  }

  allFieldsVerified () {
    return this.fields.filter((field) => {
      return field.priceMatch === true;
    }).length === this.fields.length;
  }

  disableBtn () {
    this.$btn.prop('disabled', true);
  }

  enableBtn () {
    this.$btn.prop('disabled', false);
  }
}

export default DomainPriceValidator;
