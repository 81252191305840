<template>
    <div v-if="domainRegistered">
      <div v-if="refreshing" class="alert-sm alert-default" key="refreshing">
          <h4 class="icon-refresh"><strong>Refreshing...</strong></h4>
          <p>Please wait.</p>
      </div>
      <div v-if="isPendingSuspension" class="alert-sm alert-warning" key="pending">
          <h4><strong>This domain is pending suspension for lack of registrant verification.</strong></h4>
          <p>You must verify the registrant email for this domain before {{ suspensionDate }} to avoid
              suspension.</p>
          <a :href="raaUrl" data-method="post" class="btn btn-warning">Send registrant verification email</a>
          <a href="#" v-on:click="refresh" class="btn btn-warning">Refresh</a>
      </div>
      <div v-if="isSuspended" class="alert-sm alert-error" key="suspended">
          <h4><strong>This domain is suspended for lack of registrant verification.</strong></h4>
          <p>The ICANN Registrar Accreditation Agreement (RAA) requires to validate the registrant contact
              information.</p>
          <a :href="raaUrl" data-method="post" class="btn btn-warning">Send registrant verification email</a>
          <a v-on:click="refresh" class="btn btn-warning">Refresh</a>
      </div>
      <div v-if="isProcessing" class="alert-sm alert-info" key="processing">
          <h4><strong>Registrant verification processing.</strong></h4>
          <p>The registrant verification for this domain is currently processing.</p>
      </div>
      <div v-if="isOK" class="alert-sm alert-success" key="ok">
          <h4><strong>Registrant verification succeeded.</strong></h4>
          <p>The registrant for this domain has been verified.</p>
      </div>
    </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
export default {
  props: ['domainRegistered', 'raaUrl', 'refreshUrl'],
  mounted () {
    if (this.domainRegistered)
      this.checkRegistrantVerificationStatus();
  },
  data () {
    return {
      info: {},
      refreshing: false,
      userClickedRefresh: false
    };
  },
  methods: {
    checkRegistrantVerificationStatus () {
      ajax(this.raaUrl).then(
        response => {
          this.refreshing = false;
          this.info = response.getAt('info', {});
        }
      );
    },
    refresh () {
      this.refreshing = true;
      this.userClickedRefresh = true;
      this.info = {};
      ajax(this.refreshUrl).then(
        () => this.checkRegistrantVerificationStatus()
      );
    }
  },
  computed: {
    suspensionDate () {
      return new Date((this.info.suspension_at + '').replace('Z', '')).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    isPendingSuspension () {
      return this.info.state === 'pending_suspension';
    },
    isSuspended () {
      return this.info.state === 'suspended';
    },
    isProcessing () {
      return this.info.state === 'processing';
    },
    isOK () {
      return this.info.state === 'ok' && this.userClickedRefresh === true;
    }
  }
};
</script>
