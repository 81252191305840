<template>
  <div>
    <div v-if="responseBody && responseBody.length" v-html="responseBody"></div>
    <slot v-else />
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';

export default {
  props: {
    wait: {
      type: Number,
      default: 0
    },
    interval: {
      type: Number,
      default: 30000
    },
    url: {
      type: String
    }
  },
  mounted () {
    setTimeout(() => this.load(), this.wait);
  },
  data () {
    return {
      responseBody: null
    };
  },
  methods: {
    load () {
      ajax(this.url, '', 'get', 'html')
        .then((response) => {
          this.responseBody = response.get();
        })
        .finally(() => {
          setTimeout(() => this.load(), this.interval);
        });
    }
  }
};
</script>
