<template>
  <div v-if="autoCorrected" class="suggestion-warning">
    <p class="icon-info"><strong>We have removed "www" from the domain name.</strong></p>
    Most domain names don't start with "www".<br>
    You can configure this as a subdomain like "blog", or "store".
    <div class="text-right">
      <button type="button" v-on:click="autoCorrected = false" class="btn btn-sm btn-default">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      autoCorrected: false
    };
  },
  watch: {
    query (text) {
      if (this.startsWithWWW(text)) {
        this.autoCorrected = true;
        this.$emit('trim', this.removeWWW(text));
      }
    }
  },
  methods: {
    startsWithWWW (text) {
      return text.indexOf('www.') === 0;
    },
    removeWWW (text) {
      return text.slice(4, text.length);
    }
  }
};
</script>
