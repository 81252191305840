// Use DNStripe to avoid name conflict with the Stripe library
class DNStripe {
  constructor (Stripe, publishableKey, $form, $errorDisplay, $firstName, $lastName, $address, $city, $state, $country, $submit, $expiryMonth, $expiryYear, $billingZip, $stripeToken) {
    this.$form = $form;
    this.$errorDisplay = $errorDisplay;
    this.$firstName = $firstName;
    this.$lastName = $lastName;
    this.$address = $address;
    this.$city = $city;
    this.$state = $state;
    this.$country = $country;
    this.$submit = $submit;
    this.$expiryMonth = $expiryMonth;
    this.$expiryYear = $expiryYear;
    this.$billingZip = $billingZip;
    this.$stripeToken = $stripeToken;
    this.stripe = Stripe(publishableKey);
    this.elements = this.stripe.elements();
    this.classes = {
      base: 'form-control',
      invalid: 'cc-error'
    };
    this.style = {
      base: {
        fontSize: '16px',
        lineHeight: '26px',
        '::placeholder': {
          color: '#999999'
        }
      },
      invalid: {
        color: '#FF7F2A'
      }
    };

    this.renderForm();
  }

  renderForm () {
    const $form = this.$form;
    const form = $form.get(0);

    const cardNumber = this.elements.create('cardNumber', {
      classes: this.classes,
      style: this.style,
      placeholder: 'Card number'
    });
    cardNumber.mount('#stripe-card-number');
    cardNumber.addEventListener('change', this.handleError());
    cardNumber.addEventListener('change', function (event) {
      if (event.brand)
        $form.find('#cc-brand-icon').removeClass().addClass(`cc-brand-${event.brand}`);
    });

    const cardExpiry = this.elements.create('cardExpiry', {
      classes: this.classes,
      style: this.style
    });
    cardExpiry.mount('#stripe-card-expiry');
    cardExpiry.addEventListener('change', this.handleError());

    const cardCvc = this.elements.create('cardCvc', {
      classes: this.classes,
      style: this.style
    });
    cardCvc.mount('#stripe-card-cvc');
    cardCvc.addEventListener('change', this.handleError());

    const prefilledPostalCode = $form.find('.billing-zip').val();
    const cardPostalCode = this.elements.create('postalCode', {
      classes: this.classes,
      style: this.style,
      placeholder: '12345',
      value: prefilledPostalCode
    });
    cardPostalCode.mount('#stripe-card-postal-code');
    cardPostalCode.addEventListener('change', this.handleError());

    form.addEventListener('submit', (event) => {
      event.preventDefault();

      const additionalData = {
        name: `${this.$firstName.val()} ${this.$lastName.val()}`,
        address_line1: this.$address.val(),
        address_city: this.$city.val(),
        address_state: this.$state.val(),
        address_country: this.$country.val()
      };

      return this.stripe.createToken(cardNumber, additionalData).then((result) => {
        if (result.error) {
          this.$errorDisplay.html(`<div class='alert alert-warning'>${result.error.message}</div>`);
          this.$submit.prop('disabled', true);
        } else {
          this.$expiryMonth.val(result.token.card.exp_month);
          this.$expiryYear.val(result.token.card.exp_year);
          this.$billingZip.val(result.token.card.address_zip);
          this.$stripeToken.val(result.token.id);

          form.submit();
        }
      });
    });
  }

  handleError () {
    return (event) => {
      if (event.error) {
        this.$errorDisplay.html(`<div class='alert alert-warning'>${event.error.message}</div>`);
        this.$submit.prop('disabled', true);
      } else {
        this.$errorDisplay.html('');
        this.$submit.prop('disabled', false);
      }
    };
  }

  static ready ($) {
    const publishableKey = $('#stripejs').data('pk');

    $('.js-stripe-form').ifdo(function () {
      const $form = this.parents('form');

      if (!window.Stripe)
        return $form.replaceWith(`<div class="tc">
      <img src="/assets/errors/404.png" class="w-60" />
      <h3 class="b">Uh oh. Our billing system couldn't be loaded.</h3>
      <p>If you're running an ad blocker, please turn it off.</p>
      <p>You may need to use an alternate device.</p>
      <a href="${window.location.href}" class="mt3 btn btn-primary">Refresh Page</a>
    </div>`);

      const $errorDisplay = $form.find('#stripe-card-errors');
      const $firstName = $form.find('#payment_details_first_name');
      const $lastName = $form.find('#payment_details_last_name');
      const $address = $form.find('#payment_details_billing_address');
      const $city = $form.find('#payment_details_billing_city');
      const $state = $form.find('#payment_details_billing_state');
      const $country = $form.find('#payment_details_billing_country');
      const $submit = $form.find(':submit');
      const $expiryMonth = $form.find('.expiry-month');
      const $expiryYear = $form.find('.expiry-year');
      const $billingZip = $form.find('.billing-zip');
      const $stripeToken = $form.find('.stripe-token');

      new DNStripe($, window.Stripe, publishableKey, $form, $errorDisplay, $firstName, $lastName, $address, $city, $state, $country, $submit, $expiryMonth, $expiryYear, $billingZip, $stripeToken);
    });
  }
};

export default DNStripe;
