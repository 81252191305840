class Certificates {
  constructor (dom) {
    this.dom = dom;
  }

  hook () {
    this.hookCustomCSR();
    this.hookCertificateNameNotes();
  }

  hookCertificateNameNotes () {
    if (this.dom.certificate_name !== null && this.dom.standard_note !== null && this.dom.wildcard_note !== null) {
      this.switchNotes();

      this.dom.certificate_name.addEventListener('keyup', () => this.switchNotes());
    }
  }

  hookCustomCSR () {
    if (this.dom.csr_container !== null && this.dom.certificate_provided_csr !== null) {
      this.toggleSignatureAlgorithms();
      this.toggleCustomCSRContainer();

      this.dom.certificate_provided_csr.addEventListener('change', event => {
        if (!this.dom.certificate_provided_csr.checked)
          this.dom.certificate_csr.value = '';

        this.toggleSignatureAlgorithms();
        this.toggleCustomCSRContainer();
      });
    }
  }

  switchNotes () {
    const isWildcard = /^\*/.test(this.dom.certificate_name.value);
    this.dom.standard_note.style.display = isWildcard ? 'none' : 'block';
    this.dom.wildcard_note.style.display = isWildcard ? 'block' : 'none';
  }

  toggleCustomCSRContainer () {
    this.dom.csr_container.style.display = this.dom.certificate_provided_csr.checked ? 'block' : 'none';
  }

  toggleSignatureAlgorithms () {
    this.dom.signature_algorithm_container.style.opacity = this.dom.certificate_provided_csr.checked ? '.50' : '1';
    this.dom.signature_algorithms.forEach(o => o.disabled = this.dom.certificate_provided_csr.checked);
  }

  static ready () {
    const dom = {
      certificate_csr: document.querySelector('#certificate_csr'),
      certificate_provided_csr: document.querySelector('#certificate_provided_csr'),
      certificate_name: document.querySelector('#certificate_name'),
      csr_container: document.querySelector('#csr-container'),
      standard_note: document.querySelector('#standard-note'),
      wildcard_note: document.querySelector('#wildcard-note'),
      body: document.querySelector('body'),
      signature_algorithm_container: document.querySelector('#signature-algorithm-container'),
      signature_algorithms: [...document.querySelectorAll('input[name=signature_algorithm]')]
    };

    if (document.body.classList.contains('page-certificate-new') || document.body.classList.contains('page-certificate-purchase'))
      new Certificates(dom).hook();
  }
}

export default Certificates;
