import { FILTER_BY_NAME_AND_TYPE } from '../../javascript/common/filters.js';
import { hasMatching, pushIfUnique } from '../../javascript/common/utils.js';

class ImportDNSClassifier {
  constructor (options) {
    this.existingRecords = options.existingRecords;
    this.types = options.types;
    this.uniqueTypeRules = options.uniqueTypeRules;
    this.add = [];
    this.clean = [];
    this.update = [];
  }

  classify (subdomains, records) {
    for (const type of this.types)
      for (const subdomain of subdomains) {
        const newRecords = records.filter(FILTER_BY_NAME_AND_TYPE(subdomain, type));
        const existingRecords = this.existingRecords.filter(FILTER_BY_NAME_AND_TYPE(subdomain, type));

        if (this.uniqueTypeRules.indexOf(type) !== -1)
          this._classifyUniqueTypeRecords(newRecords, existingRecords);
        else
          this._classifyOtherTypeRecords(newRecords, existingRecords);
      }
  }

  _classifyUniqueTypeRecords (newRecords, existingRecords) {
    for (const recordToImport of newRecords) {
      if (!existingRecords.length) {
        this.add.push(recordToImport);
        break;
      }

      for (const recordOnZone of existingRecords)
        if (recordToImport.content !== recordOnZone.content)
          pushIfUnique(this.update, {
            id: recordOnZone.id,
            type: recordOnZone.type,
            name: recordOnZone.name,
            current_content: recordOnZone.content,
            import_content: recordToImport.content,
            new_content: recordOnZone.content
          });
    }
  }

  _classifyOtherTypeRecords (newRecords, existingRecords) {
    let recordsDiff = [];
    const recordsMatch = [];

    for (const recordToImport of newRecords) {
      if (!existingRecords.length) {
        recordsDiff.push(recordToImport);
        continue;
      }

      for (const recordOnZone of existingRecords)
        if (recordToImport.content === recordOnZone.content) {
          recordsMatch.push(recordToImport);
          recordsDiff = recordsDiff.filter(r => !hasMatching('content', r, recordToImport));
        } else if (
          !recordsMatch.filter(r => hasMatching('content', r, recordOnZone)).length &&
          !recordsDiff.filter(r => hasMatching('content', r, recordOnZone)).length
        )
          recordsDiff.push(recordOnZone);

      if (!recordsMatch.filter(r => hasMatching('content', r, recordToImport)).length)
        recordsDiff.push(recordToImport);
    }

    for (const record of recordsDiff)
      if (record.id)
        this.clean.push({ id: record.id, type: record.type, name: record.name, content: record.content, keep: true });
      else
        this.add.push(record);
  }
}

export default ImportDNSClassifier;
