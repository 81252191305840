class VanityNameServers {
  constructor ($sourceDNSimple, $sourceExternal, $configExternal, $configRegNote, $configDNSimple) {
    this.$sourceDNSimple = $sourceDNSimple;
    this.$sourceExternal = $sourceExternal;
    this.$configExternal = $configExternal;
    this.$configRegNote = $configRegNote;
    this.$configDNSimple = $configDNSimple;
    this.events();
  }

  events () {
    this.$sourceDNSimple.click(() => {
      this.showDnsimpleSource();
    });

    this.$sourceExternal.click(() => {
      this.showExternalSource();
    });

    if (this.$sourceDNSimple.is(':checked'))
      this.showDnsimpleSource();

    if (this.$sourceExternal.is(':checked'))
      this.showExternalSource();
  }

  showDnsimpleSource () {
    this.$configExternal.hide();
    this.$configRegNote.hide();
    this.$configDNSimple.show();
  }

  showExternalSource () {
    this.$configExternal.show();
    this.$configRegNote.show();
    this.$configDNSimple.hide();
  }

  static ready ($) {
    new VanityNameServers(
      $('#vanity_name_servers_server_source_dnsimple'),
      $('#vanity_name_servers_server_source_external'),
      $('#vanity-config-external'),
      $('#vanity-config-external-regnote'),
      $('#vanity-config-dnsimple')
    );
  }
}

export default VanityNameServers;
