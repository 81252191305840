<template>

  <div class="pv4 ph3" @keydown.enter="create" @keydown.escape="cancel" v-if="state.serviceToAdd">
    <div>
      <service-image :sid="state.serviceToAdd.sid" height="60" loading="lazy" />
      <div class="lh-title mt3 f5 gray" v-html="instructions" />
    </div>

    <hr />

    <transition name="component-fade">
      <ul v-if="errors">
        <li class="text-danger" v-for="error in errors" :key="error">
          {{ error }}
        </li>
      </ul>
    </transition>

    <template v-if="service.requires_setup">
      <div class="form-floating mb2" v-for="field in service.setup.fields" :key="field.name">
        <input ref="inputs" :id="field.name" class="form-control" v-model="serviceSettings[field.name]"
          :placeholder="field.name" />
        <label :for="field.name">{{field.label}}</label>
        <p class="f6 i gray lh-title mt1 pl2" v-html="field.description" />
      </div>
    </template>

    <div class="mt3">
      <button class="btn btn-default mr2" @click="cancel">Cancel</button>
      <button class="btn btn-primary icon-plus" @click="create">Add Service</button>
    </div>

  </div>

</template>

<script>
import ServiceImage from '../service/image.vue';
import state from '../state.js';

export default {
  components: { ServiceImage },
  name: 'AppliedServiceCreate',

  data () {
    return { serviceSettings: {}, errors: null, state };
  },

  mounted () {
    this.$refs.inputs;
    if (this.$refs.inputs) this.$refs.inputs[0].focus();
  },

  computed: {
    instructions () {
      return this.state.serviceToAdd?.setup?.instructions || this.state.serviceToAdd?.description;
    },

    service () {
      return this.state.serviceToAdd;
    }
  },

  methods: {
    async create () {
      const result = await state.create(this.service, this.serviceSettings);

      if (result.statusCode === 400)
        this.errors = result.message.errors.split('. ');
      else
        this.cancel();
    },

    cancel () {
      this.state.serviceToAdd = null;
    }
  }
};
</script>
