<template>
  <div>
    <div v-if="domainNotFound">
      Sorry, we could not load any DNS records for this domain at this moment.
      <div class="form-actions">
        <a v-bind:href="urlDomain" class="btn btn-primary">Continue</a>
      </div>
    </div>
    <div v-else>
      <div v-if="progress < 100">
        Searching for DNS records
        <div class="progress">
          <div class="progress-bar" role="progressbar" v-bind:aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: progress + '%' }">
            <span class="sr-only">{{ progress }}% Complete</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="cleaningUpRecords">
          <div v-if="classifier.clean.length > 0">
            <h3>Records cleanup</h3>
            <p>You have some records on your domain that might not be needed anymore. Do you still need these?</p>
            <table class="records-table">
              <tbody>
                <tr v-for="record in classifier.clean">
                  <td class="ttu"><strong>{{ record.type }}</strong></td>
                  <td>{{ fqn(record) }}</td>
                  <td class="record-column-content">{{ record.content }}</td>
                  <td class="nowrap"><input type="radio" v-bind:name="recordId(record)" v-on:click="keepCurrentRecord(record, false)" checked="false"><span class="ml2">Remove</span></td>
                  <td class="nowrap"><input type="radio" v-bind:name="recordId(record)" v-on:click="keepCurrentRecord(record, true)" checked="false"><span class="ml2">Keep</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="classifier.update.length > 0">
            <h3>Conflicting records</h3>
            <p>The records below conflict. You can only have one value for this DNS record type. Please choose the value that you'd like to have.</p>
            <table class="records-table">
              <thead>
                <th>Type</th>
                <th>Name</th>
                <th>New content</th>
                <th>Current content</th>
              </thead>
              <tbody>
                <tr v-for="record in classifier.update">
                  <td class="ttu"><strong>{{ record.type }}</strong></td>
                  <td>{{ fqn(record) }}</td>
                  <td><input type="radio" v-bind:name="recordId(record)" v-on:click="selectRecordContent(record, record.import_content)"><span class="ml2">{{ record.import_content }}</span></td>
                  <td><input type="radio" v-bind:name="recordId(record)" v-on:click="selectRecordContent(record, record.current_content)" checked><span class="ml2">{{ record.current_content }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-actions">
            <a v-bind:href="cancelLink" class="btn btn-default">Cancel</a>
            <button v-on:click="importDNS" class="btn btn-primary" type="button">Import DNS records</button>
          </div>
        </div>
        <div v-else>
          <p>We found the following DNS records. If we missed anything, you can add more below. <br> We can import A, AAAA, CNAME, MX, and TXT records.</p>
          <div v-if="zoneVisionRecords">
            <table class="records-table">
              <thead>
                <th>Type</th>
                <th>Name</th>
                <th>Content</th>
              </thead>
              <tbody>
                <tr v-for="record in sortedZoneVisionRecords">
                  <td class="ttu"><strong>{{ record.type }}</strong></td>
                  <td>{{ fqn(record) }}</td>
                  <td class="record-column-content text-break">{{ record.preference }} {{ record.content }}</td>
                </tr>
              </tbody>
            </table>
            <div class="input-group">
              <input v-model="subdomainsInput" v-on:keyup.enter="queryCustomSubdomains(customSubdomains)" aria-label="Custom subdomains" type="text" class="form-control" placeholder="Import subdomains separating them by spaces: www blog clients..." />
              <span class="input-group-btn">
                <button v-on:click="queryCustomSubdomains(customSubdomains)" aria-label="Add custom subdomain" class="btn btn-default" type="button">Add</button>
              </span>
            </div>
            <div v-if="searchingForSubdomains" class="pt2">
              <span>Searching DNS...</span>
            </div>
            <div v-if="subdomainsErrors.length > 0" class="pt2">
              <span>We couldn't load records for <strong>{{ subdomainsErrors.join(", ") }}</strong>. Did you spell the subdomain correctly?</span>
            </div>
            <div v-if="isCleanupNeeded" class="pt5">
              <div class="form-actions">
                <a v-bind:href="cancelLink" class="btn btn-default">Cancel</a>
                <button v-on:click="cleanUpRecords" class="btn btn-primary" type="button">Continue</button>
              </div>
            </div>
            <div v-else>
              <div class="form-actions">
                <a v-bind:href="cancelLink" class="btn btn-default">Cancel</a>
                <button v-on:click="importDNS" class="btn btn-primary" type="button" aria-label="Import DNS records">Import DNS records</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
import { SORT_BY_TYPE } from '../../javascript/common/sorters.js';
import { MAP_RECORD, MAP_TRIM } from '../../javascript/common/mappers.js';
import buildRecords from './build-records.js';
import ImportDNSClassifier from './classifier.js';

export default {
  props: ['domainName', 'domainExistingRecords', 'urlZonevision', 'urlCreate', 'urlDomain', 'returnTo', 'cancelTo'],
  mounted () {
    return this.querySubdomains(this.nameQueries);
  },
  data () {
    return {
      ajax,
      domainNotFound: false,
      cleaningUpRecords: false,
      searchingForSubdomains: false,
      progress: 0,
      zoneVisionRecords: [],
      classifier: new ImportDNSClassifier({
        existingRecords: JSON.parse(this.domainExistingRecords).map(MAP_RECORD),
        types: ['a', 'aaaa', 'cname', 'mx', 'txt'],
        uniqueTypeRules: ['cname']
      }),
      subdomainsInput: '',
      nameQueries: ['', 'www', 'blog', 'email'],
      subdomainsErrors: []
    };
  },
  methods: {
    querySubdomains (subdomains, onError) {
      const promises = subdomains.map((subdomain) => {
        return this.fetchDNS(subdomain)
          .then((response) => {
            const records = buildRecords(response, subdomain);

            this.classifier.classify(this.nameQueries, records);
            this.zoneVisionRecords.push(...records);

            if (!records.length && typeof onError === 'function')
              onError(subdomain);
          })
          .catch(() => {
            if (!subdomain)
              this.domainNotFound = true;

            if (typeof onError === 'function')
              onError(subdomain);
          })
          .finally(() => this.completeFetch());
      });

      return Promise.all(promises);
    },
    queryCustomSubdomains (customSubdomains) {
      this.subdomainsErrors = [];
      this.searchingForSubdomains = true;

      customSubdomains = customSubdomains.filter(query => !this.nameQueries.includes(query));

      this.nameQueries.push(...customSubdomains);

      return this.querySubdomains(customSubdomains, (subdomain) => {
        this.subdomainsErrors.push(subdomain);
      }).finally(() => {
        this.searchingForSubdomains = false;
        this.subdomainsInput = '';
      });
    },
    fetchDNS (subdomain) {
      const hostname = subdomain ? `${subdomain}.${this.domainName}` : this.domainName;

      return this.ajax(`${this.urlZonevision}/query/${hostname}`);
    },
    importDNS () {
      return this.ajax(this.urlCreate, {
        records_to_add: JSON.stringify(this.classifier.add),
        records_to_delete: JSON.stringify(this.classifier.clean.filter(r => !r.keep)),
        records_to_update: JSON.stringify(this.classifier.update),
        return_to: this.returnTo
      }, 'post').then(
        (response) => window.location.href = response.getAt('location', this.cancelLink),
        () => { }
      );
    },
    cleanUpRecords () {
      this.cleaningUpRecords = true;
    },
    completeFetch () {
      const value = 100 / this.nameQueries.length;
      this.progress = this.progress + value;
    },
    fqn (record) {
      return record.name ? `${record.name}.${this.domainName}` : this.domainName;
    },
    keepCurrentRecord (record, value) {
      record.keep = value;
    },
    selectRecordContent (record, value) {
      record.new_content = value;
    },
    recordId (record) {
      return `${record.type}-${record.name}-${record.content}`;
    }
  },
  computed: {
    cancelLink () {
      return this.cancelTo || this.returnTo || this.urlDomain;
    },
    customSubdomains () {
      return this.subdomainsInput.trim().split(' ').map(MAP_TRIM);
    },
    isCleanupNeeded () {
      return this.classifier.clean.length > 0 || this.classifier.update.length > 0;
    },
    sortedZoneVisionRecords () {
      return this.zoneVisionRecords.slice(0).sort(SORT_BY_TYPE);
    }
  }
};
</script>
