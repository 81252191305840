<template>
  <div class="relative">
    <!-- Select -->
    <div v-if="showSelectBox">
      <div v-on:click="showItems" class="form-select js-select-box">
        <div v-if="Object.keys(selectedItem).length > 0">
          <slot name="item" v-bind="selectedItem"/>
        </div>
        <div v-else>
          <div>{{ placeholder }}</div>
        </div>
      </div>
    </div>
    <input type="hidden" v-bind:name="name" v-bind:value="selectedItem.id"/>
    <!-- Item List -->
    <div v-if="showList" class="absolute overflow-y-auto z-999 left-0 right-0 bg-white ba b--light-gray pa2 shadow-4 js-item-list" style="max-height: 400px;">
      <input type="search" autocomplete="on" aria-autocomplete="list" v-model.trim="searchText" v-bind:placeholder="filterText" class="form-control" ref="searchInputRef" />
      <div v-if="filteredItems().length">
        <div v-for="item in filteredItems()">
          <div v-on:click="selectItem(item)" class="pa2 bb b--light-gray pointer bg-animate hover-bg-light-gray js-item-select">
            <slot name="item" v-bind="item"/>
          </div>
        </div>
      </div>
      <div v-else class="pa2">No results found.</div>
      <!-- Extra slot for compoents extending this component -->
      <slot name="extra"/>
    </div>
  </div>
</template>

<script>
import clickAway from '../../javascript/utils/clickaway.js';

export default {
  props: ['name', 'collection', 'selection', 'placeholder', 'filterText'],
  mounted () {
    this.items = JSON.parse(this.collection);
    this.selectedItem = JSON.parse(this.selection);
    clickAway(this.$el, () => this.showList = false);
  },
  data () {
    return {
      items: [],
      searchText: '',
      selectedItem: {},
      showList: false,
      showSelectBox: true
    };
  },
  methods: {
    showItems () {
      this.showList = true;
      this.$nextTick(() => {
        if (this.showList) {
          this.searchText = '';
          this.$refs.searchInputRef.focus();
        }
      });
    },
    hideComponent () {
      this.showList = false;
      this.showSelectBox = false;
      this.selectedItem = {};
    },
    showComponent () {
      this.showSelectBox = true;
    },
    selectItem (item) {
      this.selectedItem = item;
      this.showList = false;
    },
    filteredItems () {
      return this.items.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase())
        )
      );
    }
  }
};
</script>
