class DomainPriceField {
  constructor ($input, priceToMatch) {
    this.$input = $input;
    this.priceToMatch = priceToMatch;
    this.priceMatch = false;
    this.listenForInput();
  }

  listenForInput () {
    this.$input.keyup(() => {
      this.verifyInput();
    });
  }

  verifyInput () {
    if (this.toMoney(this.priceToMatch) === this.toMoney(this.$input.val()))
      this.priceMatch = true;
    else
      this.priceMatch = false;
  }

  toMoney (text) {
    return text.replace('$', '').replace(',', '');
  }
}

export default DomainPriceField;
