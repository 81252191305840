<template>
  <div>
    <filter-select ref="baseComponent" v-bind:collection="collection" v-bind:selection="selection" v-bind:name="name" v-bind:placeholder="placeholder" v-bind:filter-text="filterText">
      <template v-slot:item="{ label, name, organization, job, address, postal_routing, email }">
        <div v-if="label">
          <span class="db b">{{ label }}</span>
          <span class="db">{{ name }}</span>
        </div>
        <div v-else>
          <span class="db b">{{ name }}</span>
        </div>
        <div v-if="organization">
          <span class="db">{{ organization }}</span>
        </div>
        <div v-if="job">
          <span class="db">{{ job }}</span>
        </div>
        <span class="db">{{ address }}</span>
        <span class="db">{{ postal_routing }}</span>
        <span class="db">{{ email }}</span>
      </template>
      <template v-slot:extra>
        <button v-on:click="addNewContact" class="mv3 btn btn-default">Add new contact</button>
      </template>
    </filter-select>
    <div v-if="showNewContact">
      <button v-on:click="showBaseComponent" class="mv3 btn btn-default">Select existing contact</button>
      <slot name="new-contact"/>
    </div>
  </div>
</template>

<script>
import FilterSelect from '../filter-select/component.vue';

export default {
  props: ['name', 'collection', 'selection', 'placeholder', 'filterText', 'validContact'],
  components: { FilterSelect },
  mounted () {
    if (this.validContact === 'false')
      this.addNewContact();
  },
  data () {
    return {
      showNewContact: false
    };
  },
  methods: {
    addNewContact () {
      this.$refs.baseComponent.hideComponent();
      this.showNewContact = true;
    },
    showBaseComponent () {
      this.showNewContact = false;
      this.$refs.baseComponent.showComponent();
    }
  }
};
</script>
