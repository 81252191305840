<template>
  <div>
    <input type="number" v-bind:name="inputName" v-model="value" v-bind:min="min" v-bind:class="clazz" v-bind:autofocus="autofocus">
    <slot name="less" v-if="value < other" v-bind:value="value" v-bind:difference="other - value"></slot>
    <slot name="greater" v-if="value > other" v-bind:value="value" v-bind:difference="other - value"></slot>
    <slot name="equals" v-if="value === other" v-bind:value="value" v-bind:difference="other - value"></slot>
  </div>
</template>

<script>
import debounce from '../../javascript/utils/debounce.js';

export default {
  props: ['inputName', 'initialValue', 'compareTo', 'min', 'autofocus', 'clazz'],
  data () {
    return {
      value: 0,
      other: 0
    };
  },
  mounted () {
    this.value = parseInt(this.initialValue);
    this.other = parseInt(this.compareTo);
  },
  watch: {
    value: debounce(function (newValue) {
      if (newValue < this.min)
        this.value = this.min;
    }, 500)
  }
};
</script>
