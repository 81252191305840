<template>
  <div class="mt4 pt0 animation-fadein">
    <div class="action-card-container">
      <div class="mw-100 w-100">
        <div class="domain-connections-table overflow-y-auto mb4 ba b--moon-gray br3">
          <table class="table table-striped w-100">
            <tbody>
              <tr v-for="message in messages" class="ds-platform-message">
                <td>
                  <strong>{{ message.title }}</strong
                  ><br>
                  <span v-html="linkify(message.message)" aria-label="connection message"></span><br>
                  <span class="text-subtle">{{ message.created_at }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="action-card-container">
      <p>See the metadata of this connection</p>
      <modal close-text="Close" ref="modal" v-cloak>
        <template v-slot:button>
          <a class="action-link pointer">View Metadata</a>
        </template>
        <template v-slot:header>
          <div>Connection Metadata</div>
        </template>
        <template v-slot:body>
          <div>
            <table class="card-table w-100">
              <tbody>
                <tr
                  v-for="(value, key) in metadata"
                  class="ds-platform-metadata-entry"
                >
                  <td>{{ key }}</td>
                  <td>
                    <strong>{{ value }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </modal>
    </div>
    <template v-if="isReconnectable">
      <div class="card-extra-box mt4 pb4"></div>
      <div class="action-card-container">
        <p>Reconnect this addon to this domain</p>
        <a :href="reconnectUrl" class="action-link" aria-label="Reconnect">Reconnect</a>
      </div>
    </template>
    <div class="card-extra-box mt4 pb4"></div>
    <div class="action-card-container">
      <p>Disconnect this addon to this domain</p>
      <a :href="disconnectUrl" class="action-link">Disconnect</a>
    </div>
  </div>
</template>

<script>
import Modal from '../modal/component.vue';

export default {
  props: {
    connectionId: { type: Number, default: 0 },
    messagesJsonString: { type: String, default: '' },
    metadataJsonString: { type: String, default: '' },
    iconUrl: { type: String, default: '' },
    disconnectUrl: { type: String, default: '' },
    reconnectUrl: { type: String, default: '' },
    title: { type: String, default: '' },
    status: { type: String, default: '' },
    updatedAt: { type: String, default: '' }
  },
  components: {
    Modal
  },
  computed: {
    color () {
      switch (this.status) {
        case 'error':
          return 'red';
        case 'warning':
          return 'yellow';
        case 'ok':
          return 'green';
        default:
          return 'gray';
      }
    },
    statusClass () {
      return `icon-circle icon-${this.color}`;
    },
    isReconnectable () {
      return this.status !== 'ok';
    }
  },
  data () {
    return {
      messages: JSON.parse(this.messagesJsonString || '[]'),
      metadata: JSON.parse(this.metadataJsonString || '[]')
    };
  },
  methods: {
    linkify (str) {
      const URL_DETECTOR = /(?<space>[$\s]+)(?<url>https?:\/\/([A-Za-z0-9-]+\.)+[A-Za-z0-9]+)/gi;
      const DOMAIN_NAME_DETECTOR = /(?<space>[$\s]+)(?<domain>([A-Za-z0-9-]+\.)+[A-Za-z0-9]+)/gi;

      return str
        .replace(URL_DETECTOR, '$<space><a href="$<url>" target="_blank">$<url></a>')
        .replace(DOMAIN_NAME_DETECTOR, '$<space><a href="http://$<domain>/" target="_blank">$<domain></a>');
    }
  }
};
</script>
