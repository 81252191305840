import ajax from '../../common/ajax.js';

class DashboardDomainFavorite {
  constructor ($el, $overlay, btns) {
    this.$el = $el;
    this.url = this.$el.data('url');
    this.$overlay = $overlay;
    this.btns = btns;
    this.events();
  };

  events () {
    this.$el.on('click', this.btns, (e) => {
      e.preventDefault();
      const favoriteDomainUrl = this.$(e.currentTarget).data('url');
      this.favorite(favoriteDomainUrl);
    });
  };

  favorite (favoriteDomainUrl) {
    this._showOverlay();

    return ajax(favoriteDomainUrl, '', 'post')
      .then(() => this.done())
      .catch((err) => this.fail(err));
  };

  done () {
    return ajax(this.url, '', 'get', 'html')
      .then((response) => this.reload(response.get()))
      .catch((err) => this.fail(err));
  };

  reload (html) {
    this.$el.html(html);
    this._hideOverlay();
  };

  fail (err) {
    this._hideOverlay();
    alert(`Sorry we can't update favorites at this moment: ${err.responseText}`);
  };

  _hideOverlay () {
    this.$overlay.addClass('is-hidden');
  };

  _showOverlay () {
    this.$overlay.removeClass('is-hidden');
  };

  static ready ($) {
    $('.js-dashboard-domain-list').ifdo(function () {
      new DashboardDomainFavorite(this, $('.js-dashboard-domain-overlay'), '.js-dashboard-domain-favorite-btn');
    });
  };
};

export default DashboardDomainFavorite;
