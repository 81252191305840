<template>
  <div class="dns-editor relative">
    <dns-index />
    <loading-indicator :delay="200" v-if="state.loading"/>
  </div>
</template>

<script>
import state from './state.js';
import DnsIndex from './index.vue';
import LoadingIndicator from '../loading-indicator/component.vue';

export default {
  name: 'DnsEditor',
  components: { DnsIndex, LoadingIndicator },

  props: {
    domainName: { type: String, required: true },
    accountId: { type: Number, required: true }
  },

  provide: () => ({ state }),

  data () {
    return { state, advancedEditorUrl: `/a/${this.accountId}/domains/${this.domainName}/records` };
  },

  mounted () {
    state.accountId = this.accountId;
    state.domainName = this.domainName;
    state.loadIndex();
    state.loadTypes();
  }
};
</script>
