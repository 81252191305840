<template>
  <div class="loading-cover" @click.stop.prevent>
    <div class="indicator" v-if="show">
      <slot>Loading...</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    delay: { type: Number, default: 200 }
  },

  data () {
    return { show: false };
  },

  mounted () {
    setTimeout(() => {
      this.show = true;
    }, this.delay);
  }
};
</script>

<style scoped>

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loading-cover {
  position: absolute;
  background-color: rgba(0,0,0,0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: wait;
}

.indicator {
  animation-name: fadeIn;
  animation-duration: .4s;
  animation-timing-function: ease-out;
  background-color: white;
  border: 3px solid rgba(0,0,0,.2);
  padding: 2rem 2.5rem;
  font-weight: bold;
  border-radius: 1rem;
  user-select: none;
}
</style>
