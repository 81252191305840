<template>
  <input type="checkbox" v-model="checked">
</template>

<script>
const ONE_DAY = 1 * 24 * 60 * 60 * 1000;

export default {
  props: {
    cookieName: {
      type: String,
      default: 'cookie'
    },
    expiresIn: {
      type: Number,
      default: ONE_DAY
    },
    setCookie: {
      type: Function,
      default (name, value, expiresAt) {
        document.cookie = `${name}=${value}; expires=${expiresAt}; path=/`;
      }
    },
    removeCookie: {
      type: Function,
      default (name) {
        const expiresAt = new Date(0).toGMTString();
        document.cookie = `${name}=; expires=${expiresAt}; path=/`;
      }
    }
  },
  data () {
    return {
      checked: false
    };
  },
  watch: {
    checked () {
      this.change();
    }
  },
  methods: {
    change () {
      if (this.checked) {
        const now = new Date().getTime();
        const expiresAt = new Date(now + this.expiresIn).toGMTString();
        this.setCookie(this.cookieName, 1, expiresAt);
      } else
        this.removeCookie(this.cookieName);
    }
  }
};
</script>
