<template>
  <div>
    <div v-if="hasFailed || hasCreatedFailed" class="alert alert-error validation-errors">
      <p>The data is invalid, please check the error(s) below: {{ errorMessage }}</p>
    </div>
    <table class="dt--fixed">
      <thead class="db db dt-row-group-ns">
        <th class="w-40 dn db-ns dtc-ns">Source</th>
        <th class="w-50 dn db-ns dtc-ns">Destination</th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="record in records" v-bind:key="record.id" class="db dt-row-ns ph3 striped--light-gray" v-bind:class="rowClass(record)">
          <template v-if="isEditing(record)">
            <td class="db pr3" style="height:42px;">
              <span class="db dn-ns gray text-xs">Source</span>
              <div class="input-group" style="padding-top:2px;">
                <input v-model="name" v-focus v-on:keyup.enter="updateRecord(record)" type="text" class="form-control" placeholder="www" aria-describedby="input-group-domain-name">
                <span class="input-group-addon" id="input-group-domain-name">.{{ truncate(domainName, 20) }}</span>
              </div>
            </td>
            <td class="db dtc-ns">
              <span class="db dn-ns gray text-xs">Destination</span>
              <input v-model="content" v-on:keyup.enter="updateRecord(record)" type="text" class="form-control"/>
            </td>
            <td class="tr dib pa3 pa0-ns dtc-ns pv3-ns">
              <button type="button" class="button-reset bn bg-transparent icon-check dim" v-on:click="updateRecord(record)"></button>
            </td>
            <td class="tr dib pa3 pa0-ns fr fn-ns dtc-ns">
              <button type="button" class="button-reset bn bg-transparent icon-remove dim" v-on:click="cancelEdit(record)"></button>
            </td>
          </template>
          <template v-else>
            <td class="pv3 w-50 text-wrap db dtc-ns">
              <span class="db dn-ns gray text-xs">Source</span>
              <span class="pl3-ns">{{ truncate(record.qualifiedName, 28) }}</span>
            </td>
            <td class="pv3-ns w-50 text-wrap db dtc-ns">
              <span class="db dn-ns gray text-xs">Destination</span>
              <span>{{ record.content }}</span>
            </td>
            <td class="tr dib pa3 pa0-ns dtc-ns"><button type="button" class="button-reset bn bg-transparent icon-edit dim" v-on:click="editRecord(record)"></button></td>
            <td class="tr fr pa3 pa0-ns fn-ns"><button type="button" class="button-reset bn bg-transparent icon-trash dim" v-on:click="deleteRecord(record)"></button></td>
          </template>
        </tr>
        <tr v-bind:key="0" v-bind:class="hasErrorCreating()" class="db dt-row-ns">
          <td class="pt3 pr3 db dtc-ns">
            <div class="input-group">
              <input v-model="newName" type="text" class="form-control" placeholder="www" aria-describedby="input-group-domain-name">
              <span class="input-group-addon" id="input-group-domain-name">.{{ truncate(domainName, 20) }}</span>
            </div>
          </td>
          <td class="pt3 db dtc-ns"><input v-model="newContent" v-on:keyup.enter="createRecord" type="text" class="form-control"/></td>
          <td colspan="2" class="tr pt3 db dtc-ns"><button type="button" v-on:click="createRecord" class="btn btn-primary">Add</button></td>
        </tr>
        <tr v-bind:key="-1" class="db dt-row-ns">
          <td colspan="4">
            <span class="help-block">Leave blank for root/apex domain. Use a * for wildcard redirection.</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
export default {
  props: ['domainName', 'createUrl', 'updateUrl', 'deleteUrl', 'existingRecords'],
  mounted () {
    const records = JSON.parse(this.existingRecords);
    for (const record of records)
      this.records.push({ id: record.id, name: record.name, qualifiedName: record.qualified_name, content: record.content, status: '' });
  },
  data () {
    return {
      records: [],
      name: '',
      content: '',
      newName: '',
      newContent: '',
      hasFailed: false,
      hasCreatedFailed: false,
      errorMessage: ''
    };
  },
  watch: {
    newName () {
      if (this.newName === '' && this.newContent === '')
        this.hasCreatedFailed = false;
    }
  },
  methods: {
    createRecord () {
      this.hasCreatedFailed = false;
      ajax(this.createUrl, { name: this.newName, content: this.newContent }, 'post').then(
        response => {
          const result = response.getAt('result', {});
          this.records.push(
            {
              id: result.id,
              name: result.name,
              qualifiedName: result.qualified_name,
              content: result.content,
              status: ''
            }
          );
          this.newName = '';
          this.newContent = '';
        },
        error => {
          this.hasCreatedFailed = true;
          this.errorMessage = error.getMessage('Validation failed');
        });
    },
    updateRecord (record) {
      this.hasFailed = false;
      ajax(this.updateUrl, { id: record.id, name: this.name, content: this.content }, 'put').then(
        response => {
          const result = response.getAt('result', {});
          this.records = this.records.map(record => {
            if (record.id === result.id)
              return {
                id: result.id,
                name: result.name,
                content: result.content,
                qualifiedName: result.qualified_name,
                status: ''
              };
            else
              return record;
          });
        },
        error => {
          this.hasFailed = true;
          this.errorMessage = error.getMessage('Validation failed');
          record.status = 'error';
        });
    },
    editRecord (record) {
      this.name = record.name;
      this.content = record.content;
      record.status = 'editing';
    },
    deleteRecord (record) {
      this.hasFailed = false;
      ajax(this.deleteUrl, { id: record.id }, 'delete').then(
        response => {
          this.records = this.records.filter(obj => obj.id !== record.id);
        },
        error => {
          this.errorMessage = error.getMessage('Cannot delete record. Please try again later.');
          this.hasFailed = true;
        });
    },
    cancelEdit (record) {
      record.status = '';
      this.hasFailed = false;
    },
    isEditing (record) {
      return record.status === 'editing' || record.status === 'error';
    },
    rowClass (record) {
      if (record.status === 'error')
        return 'has-error';
    },
    hasErrorCreating () {
      if (this.hasCreatedFailed === true)
        return 'has-error';
    },
    truncate (text, maxLength) {
      if (!text) return '';
      if (text.length > maxLength) return `${text.substring(0, maxLength)}...`;
      return text;
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus();
      }
    }
  }
};
</script>
