import ajax from '../../common/ajax.js';
import Processing from '../../legacy/common/processing.js';
import Modal from 'bootstrap/js/dist/modal';
import { MAP_TRIM, MAP_FLAT } from '../../common/mappers.js';
import { FILTER_UNIQ } from '../../common/filters.js';

// Executes a function for each item in series
async function executeInSeries (items, func) {
  for (let i = 0; i < items.length; i++)
    await func(items[i]);
};

class DomainListLabels {
  constructor ($, $btn, $modal, $input, $text, $addBtn) {
    this.$ = $;
    this.$btn = $btn;
    this.$modal = $modal;
    this.$input = $input;
    this.$text = $text;
    this.$addBtn = $addBtn;
    this.events();
  }

  events () {
    this.$btn.addEventListener('click', () => {
      this.showModal();
    });

    this.$addBtn.addEventListener('click', () => {
      this.addLabels(this.$selectedDomains);
    });

    this.$input.addEventListener('keyup', (e) => {
      if (e.which === 13)
        this.addLabels(this.$selectedDomains);
    });
  }

  showModal () {
    this.$selectedDomains = this.$('.js-selected-domain:checked');
    this.showDomainList(this.$selectedDomains);
    this.showExistingLabels(this.$selectedDomains);
    this.$modal.show();
  }

  showDomainList ($selectedDomains) {
    this.$text.innerHTML = '';

    if ($selectedDomains.length > 0) {
      const domains = [...$selectedDomains].map((el) => this.$(el).val());

      this.$text.innerHTML = '<strong>Selected domains</strong> <br> &bull; ' + domains.join('<br> &bull; ');
    } else
      this.$text.innerHTML = "<span class='icon-warning text-danger'>You have not selected any domain to update</span>";
  }

  showExistingLabels ($selectedDomains) {
    let allLabels;

    this.$input.value = '';

    if ($selectedDomains.length > 0) {
      allLabels = [];

      $selectedDomains.each((index, el) => {
        const labels = '' + this.$(el).data('labels');

        if (labels.length > 0)
          allLabels.push(labels.split(',').map(MAP_TRIM));
      });

      this.$input.value = MAP_FLAT(allLabels).filter(FILTER_UNIQ).join(', ');
    }
  }

  async addLabels ($selectedDomains) {
    Processing.start(this.$('#domain-listing'));

    const $domains = [...$selectedDomains];
    const labels = this.$input.value;

    if (!$domains.length) {
      alert('No domains were selected.');
      return;
    }

    this.$addBtn.innerText = 'Loading...';
    this.$addBtn.disabled = true;

    try {
      await executeInSeries($domains, ($domain) => {
        return ajax($domain.getAttribute('data-labels-path'), { labels }, 'put');
      });
    } catch (e) {
      alert("Sorry we can't update apply labels at this moment");
    }

    window.location.reload();
  }

  static ready ($) {
    $('.js-domain-list-label-btn').ifdo(() => {
      new DomainListLabels(
        $,
        document.querySelector('.js-domain-list-label-btn'),
        new Modal($('#domain-list-labels-modal')),
        document.querySelector('.js-domain-labels-input'),
        document.querySelector('.js-domains-to-label-text'),
        document.querySelector('.js-domain-list-labels-modal-btn')
      );
    });
  };
};

export default DomainListLabels;
