<template>
  <div class="flash-msg-container pa3">
    <msg @remove="remove" v-for="flash in messages" :type="flash.type" :key="flash.id" :msg="flash.msg" :id="flash.id" :duration="duration" />
  </div>
</template>

<script>
import Msg from './message.vue';
import EventBus from '../../javascript/common/event-bus.js';

export default {
  name: 'FlashMsgs',
  components: { Msg },

  data () {
    return { messages: [] };
  },

  mounted () {
    EventBus.$on('flash:add', (msg, type = 'success') => {
      this.messages.push({ msg, type, id: new Date().getTime() });
    });
  },

  methods: {
    remove (id) {
      const index = this.messages.findIndex((msg) => msg.id === id);
      this.messages.splice(index, 1);
    }
  },

  props: {
    duration: { type: Number, default: 3000 }
  }
};
</script>

<style scoped>
.flash-msg-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100vw;
}
</style>
