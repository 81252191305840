<template>
  <div>
    <input
      v-model.trim="query"
      v-on:keydown.enter.prevent="chooseSelection()"
      :placeholder="inputPlaceholder"
      :id="inputId"
      :class="inputClass"
      :name="inputName"
      :required="inputRequired"
      :autofocus="inputAutofocus"
      :autocomplete="inputAutocomplete"
      ref="input"
    >

    <WWWTrimmer
      :query="query"
      v-on:trim="setQuery"
    />
  </div>
</template>

<script>
import WWWTrimmer from '../www-trimmer/component.vue';

export default {
  components: {
    WWWTrimmer
  },
  props: {
    suffixesString: {
      type: String,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputName: {
      type: String,
      default: ''
    },
    inputRequired: {
      type: String,
      default: ''
    },
    inputAutofocus: {
      type: String,
      default: ''
    },
    inputAutocomplete: {
      type: String,
      default: ''
    },
    inputValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      query: this.inputValue
    };
  },
  methods: {
    chooseSelection () {
      const $form = this.$refs.input.form;

      $form.dispatchEvent(new Event('submit')); // Disable the form based on it's `disable_with` attribute
      $form.submit();
    },
    setQuery (query) {
      this.query = query;
    }
  }
};
</script>
