class TemplateRecords {
  constructor ($recordType, $helpBlock, $nameInput, $nameAbbr, $prioGroup) {
    this.$recordType = $recordType;
    this.$helpBlock = $helpBlock;
    this.$nameInput = $nameInput;
    this.$nameAbbr = $nameAbbr;
    this.$prioGroup = $prioGroup;
    this.events();
  }

  events () {
    this.disableCNAMEorNS();

    this.$recordType.change(() => {
      this.$prioGroup.hide();

      if (this.$recordType.val() === 'CNAME' || this.$recordType.val() === 'NS')
        this.enableCNAMEorNS();
      else
        this.disableCNAMEorNS();

      if (this.$recordType.val() === 'MX' || this.$recordType.val() === 'SRV')
        this.$prioGroup.show();
    });
  }

  enableCNAMEorNS () {
    this.$helpBlock.hide();
    this.$nameInput.removeClass('optional');
    this.$nameInput.addClass('required');
    this.$nameAbbr.show();
  }

  disableCNAMEorNS () {
    this.$helpBlock.show();
    this.$nameInput.removeClass('required');
    this.$nameInput.addClass('optional');
    this.$nameAbbr.hide();
  }

  static ready ($) {
    $('body.page-add-template-record').ifdo(function () {
      new TemplateRecords(
        $('#dns_template_record_record_type'),
        $('#dns_template_record_name_input .help-block'),
        $('#dns_template_record_name_input'),
        $('#dns_template_record_name_input label abbr'),
        $('#dns_template_record_prio_control_group')
      );
    });
  }
}

export default TemplateRecords;
